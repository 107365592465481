import { useContext, useMemo } from "react";
import { RoutingContext } from "../Context/Routing.Context";
import { RetailerContext } from "../Context/Retailer.Context";

export const useRetailer = () => {
    return useContext(RetailerContext);
};

export const useRetailerLinks = () => {
    const { isHomeDepotApp } = useRetailer();

    const generateLink = useMemo(() => {
        return (href) => isHomeDepotApp.isHomeDepotActive ? `/home-depot${href}` : href;
    }, [isHomeDepotApp.isHomeDepotActive]);

    return { generateLink };
};


export const useRoutingHook = () => {
    return useContext(RoutingContext)
}


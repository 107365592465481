




// -------------------------------------------
//--STRING MANIPULATION
// -------------------------------------------


export const capitalizeFirstLetterEachWord = (str) => {
  return str?.replace(/\b\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
};

export const TruncateText = (str) => {
  const text = str.length > 500 && '...see details';
  return text;
}




// ---------------------------------------------------------
// URL TRANSFORMATION
// ---------------------------------------------------------

export const GenerateProductURL = (category, productTitle) => {
  const encodedTitle = encodeURIComponent(productTitle);
  return `/appliances/${category}/${encodedTitle}`;
};

export const homeLink = '/';
export const homeDepotRoot = `/home-depot/`
export const portalLink = `/portal/dashboard`;



// ---------------------------------------------------------
// PRODUCTS
// ---------------------------------------------------------

export const FilterProductById = (products, productId) => {
  return products.filter(product => product.title === productId);
};




export const consolidateSpecListsToArray = (product) => {
  let consolidatedList = [];
  
  // List of spec keys to extract and consolidate
  const specLists = ['specList1EN', 'specList2EN', 'specList3EN', 'specList4EN'];

  // Iterate through each spec list in the product and concatenate it to the consolidatedList
  specLists.forEach(specList => {
    if (Array.isArray(product[specList]) && product[specList].length > 0) {
      consolidatedList = consolidatedList.concat(product[specList]);
    }
  });

  return consolidatedList;
};
export const extractProductNamesFromKeywords = (keywords) => {
  const productNames = [];
  const targetKeywords = new Set(['related','optional', 'matching', 'accessories']);
  const regex = /\(([^)]+)\)/g;

  keywords.forEach(keyword => {
    const lowerCaseKeyword = keyword.toLowerCase();

    // Check if the line contains one of the target keywords
    if ([...targetKeywords].some(target => lowerCaseKeyword.includes(target))) {
      let match;

      // Extract product names from within brackets
      while ((match = regex.exec(keyword)) !== null) {
        const names = match[1].split(',').map(name => name.trim());
        productNames.push(...names);
      }

      // Extract product name from the end of the line (last word)
      const words = keyword.trim().split(' ');
      const productName = words[words.length - 1];
      productNames.push(productName);
    }
  });

  return productNames;
};
;
export const findMatchingProducts = (productNames, productList) => {

  if (!Array.isArray(productList)) {
    console.error('productList is not an array or is undefined');
    return [];
  }
  const nameSet = new Set(productNames);
  return productList.filter(product => nameSet.has(product.title));
};

import styles from "./LeaderBoard.module.css";

const LeaderBoard = ({ title, subtitle, data, formatter }) => {

    return (
        <div className={styles.leaderBoardTableContainer}>
            <div className={styles.textBox}>
                <p className={styles.title}>{title}</p>
                <p className={styles.subtitle}>{subtitle}</p>
            </div>
            <div className={styles.leaderBoardTableWrapper}>


                {data && data.map((item, index) => formatter(item, index))}
            </div>
        </div>
    );
}

export default LeaderBoard;
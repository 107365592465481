import {jwtDecode} from "jwt-decode";
import { useContext, useState, useMemo } from "react";
import { AdminContext } from "../../../Context/Admin.Context";
import { AuthContext } from "../../../Context/Auth.Context";
import { RoutingContext } from "../../../Context/Routing.Context";
import { RetailerContext } from "../../../Context/Retailer.Context";
import Icon from "../../../Shared/Components/IconElements/Icon";
import TheTable from "../../PortalComponents/TableElements/TheTable"
import TheTableHeader from "../../PortalComponents/TableElements/TheTableHeader"
import TheTablePagination from "../../PortalComponents/TableElements/TheTablePagination"
import TheTableActionBox from "../../PortalComponents/TableElements/TheTableActionBox";
import Modal from "../../../Shared/Components/UI/TheModals/TheModal";
import TheButton from "../../../Shared/Components/UI/TheButton/TheButton";

import { useNavigate } from "react-router";
import TheIcon from "../../../Shared/Components/UI/TheIcon/TheIcon";
import TheTableWrapper from "../../PortalComponents/TableElements/TheTableWrapper";
const AllAdminUsersTablePage = () => {

    const redirect = useNavigate()
    const { setIsRoutingState } = useContext(RoutingContext);
    const { isHomeDepotApp } = useContext(RetailerContext);
    const { token } = useContext(AuthContext);
    const decodedToken = token ? jwtDecode(token) : null;

    const {
        setIsActiveSection,
        setIsPortalActionState,
        setUpdateTheUser,
        allAdminUsers,
        isPortalDataState
    } = useContext(AdminContext);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedUserForDeletion, setSelectedUserForDeletion] = useState(null)

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Function to handle sort change
    //  const handleSortChange = () => {  // NEW
    //     setSortDescending(prevState => !prevState);
    // }
    const defaultModal = {
        show: false,
        title: '',
        message: '',
        errorList: [],
        actionText: 'Close',
        wasSuccessful: false,
        onConfirm: null,
        onCancel: null,
        onClose: null,
        cancelText: '',
        confirmText: '',
    }
    const [modalInfo, setModalInfo] = useState(defaultModal);
    const columns = [

        // {
        //     key: 'store',
        //     title: 'Retailer',
        //     render: row => row.store ? row.store : 'Not Provided'
        // },
        { key: 'firstName', title: 'First Name' },
        { key: 'email', title: 'Email' },
        {
            key: 'role',
            title: 'Role',
            render: row =>
                row.role === "superAdmin"
                    ? "Super Admin"
                    : row.role === "admin"
                        ? "Admin"
                        : row.role
        },

      

     
        {

            title: 'Products Created',
            render: row => row.productsCreated ? row.productsCreated : '0'
        },
        {
            title: 'Products Updated',
            render: row => row.productsUpdated ? row.productsUpdated : '0'
        },
        {
            title: 'Products Deleted',
            render: row => row.productsDeleted ? row.productsDeleted : '0'
        },
        // {
        //     title: 'Users Created',
        //     render: row => row.usersCreated ? row.usersCreated : '0'
        // },

        // {
        //     title: 'Users Updated',
        //     render: row => row.usersUpdated ? row.usersUpdated : '0'
        // },
        // {
        //     title: 'Users Deleted',
        //     render: row => row.usersUpdated ? row.usersDeleted : '0'
        // },

        // { key: 'createdAt', title: 'Joined', render: row => format(new Date(row.createdAt), 'dd/mm/yyyy') },
        {
            key: 'actions',
            title: 'Actions',
            render: row => (

                <TheTableActionBox>
                    <span
                        onClick={
                            () => {
                                // setIsActiveSection(9)
                                // setIsEditingUser(row._id)
                                setIsActiveSection(9)
                                // handleSectionClick(9)
                                setIsPortalActionState(prevState => ({
                                    ...prevState,
                                    editingUser: row._id,
                                }))
                            }
                        }
                    >
                        <Icon name="accountLocked" />
                    </span>
                    <span
                        onClick={
                            () => {
                                // setActiveSection(7)
                                // setIsEditingUser(row._id)
                                setIsActiveSection(7)
                                setIsPortalActionState(prevState => ({
                                    ...prevState,
                                    editingUser: row._id,
                                }))
                            }
                        }
                    >
                        <Icon name="admin" />
                    </span>

                    <span
                        // className={styles.deleteIconBox}
                        onClick={
                            () => {
                                setSelectedUserForDeletion(row?._id);
                                // setShowConfirmationModal(true);
                                setModalInfo({
                                    show: true,
                                    title: 'Are you sure you want to proceed?',
                                    message: `You are about to delete an admin user. Please confirm to continue.`,
                                    confirmText: "Confirm",
                                    // onConfirm: handleDeleteClick,
                                    onConfirm: deleteSelfHandler,
                                    cancelText: "Go back",
                                    onCancel: () => setModalInfo({ ...modalInfo, show: false }),
                                    onClose: () => setModalInfo({ ...modalInfo, show: false }),
                                });
                            }
                        }
                    >
                        <TheIcon name="delete" />

                    </span>
                </TheTableActionBox>

            )
        },
    ];


    const userDeleteSuccessHanlder = () => {
        setModalInfo({ ...modalInfo, show: false });
        setIsActiveSection(2);
        redirect('/admin')
    }
    const deleteSelfHandler = () => {

        if (decodedToken.userId === selectedUserForDeletion) {
            setModalInfo({
                show: true,
                title: 'Deletion failed',
                message: 'You cannot delete your own admin account from this location. Please visit your account profile page to delete your account.',
                errorList: [],
                confirmText: 'Visit profile page',
                cancelText: "Go back",
                wasSuccessful: true,
                onCancel: () => setModalInfo({ ...modalInfo, show: false }),
                // onConfirm: redirectToProfilePage,
                onConfirm: () => redirect(`${isHomeDepotApp?.isHomeDepotPrefix}/user/${decodedToken?.userId}`),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });
            return

        } else {
            handleDeleteClick()
        }

    }
    // const redirectToProfilePage = () => {
    //     redirect(`${isHomeDepotApp?.isHomeDepotPrefix}/admin${decodedToken?.userId}`)
    // }
    const handleDeleteClick = async () => {

        setIsRoutingState(prevState => ({ ...prevState, isLoading: true }))
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/delete-admin/${selectedUserForDeletion}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    adminId: decodedToken.userId,
                })
            });
            if (response.status === 200) {
                setModalInfo({
                    show: true,
                    title: 'User deleted',
                    message: 'The user was deleted successfully!',
                    errorList: [],
                    confirmText: 'Close',
                    wasSuccessful: true,
                    onConfirm: userDeleteSuccessHanlder,
                    onClose: userDeleteSuccessHanlder
                });
            } else {
                setModalInfo({
                    show: true,
                    title: 'Something went wrong',
                    message: 'The user could not be deleted. Please contact an administrator for assistance.',
                    errorList: [],
                    confirmText: 'Try again',
                    onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                    onClose: () => setModalInfo({ ...modalInfo, show: false }),
                });
            }


        } catch (error) {
            setModalInfo({
                show: true,
                title: 'Something went wrong',
                message: 'The user could not be deleted. Please contact an administrator for assistance.',
                errorList: error.message, //TEMP - should be []
                confirmText: 'Try again',
                onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });
        } finally {
            setIsRoutingState(prevState => ({ ...prevState, isLoading: false }))
        }
    };

    return (
        <>
            <Modal
                icon="true"
                iconName="close"
                styleName="baseIconButton"
                iconOnClick={modalInfo.onClose}
                show={modalInfo.show}
                title={modalInfo.title}
                message={modalInfo.message}
                errorList={modalInfo.errorList}
                closeOnBackdropClick={false}
                actionText={modalInfo.actionText}
                actionBox={
                    <>
                        {modalInfo.onConfirm && (
                            <span onClick={modalInfo.onConfirm}>
                                <TheButton styleName="red" animated='true'>
                                    {modalInfo.confirmText}
                                </TheButton>
                            </span>
                        )}
                        {modalInfo.onCancel && (
                            <span onClick={modalInfo.onCancel}>
                                <TheButton styleName="black" animated='true'>
                                    {modalInfo.cancelText}
                                </TheButton>
                            </span>
                        )}
                    </>
                }
            />
            <TheTableWrapper>
                <TheTableHeader
                    title="Admin Users"
                    subtitle="Admin Portal"
                    headline="All administrative users"
                />
                <TheTable columns={columns} data={isPortalDataState.allAdminUsers && isPortalDataState.allAdminUsers?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)} />
                <TheTablePagination
                    itemsPerPage={itemsPerPage}
                    tableData={isPortalDataState?.allAdminUsers}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            </TheTableWrapper>
        </>
    )
}

export default AllAdminUsersTablePage;
import ProductCard from "../Cards/ProductCard";

import { useState, useEffect, useRef } from "react";
import { GenericScrollHeader } from "../../../../components/ScrollingComponent/Header/GenericScrollHeader"

import styles from './MatchingProductComponent.module.css'
import BodyContainer from "../../UI/BodyContainer/BodyContainer";
import ScrollableBodyContainer from "../../UI/BodyContainer/ScrollableBodyContainer";
export const MatchingProductComponent = ({ product }) => {


    const [scrollDisabled, setScrollDisabled] = useState({});
    const scrollRef = useRef();

    useEffect(() => {
        const initialState = { left: true, right: false };
        setScrollDisabled(initialState);
        if (scrollRef.current) {
            updateScrollButtons(scrollRef);
        }
    }, [scrollRef]);

    const updateScrollButtons = (scrollWrapper) => {
        if (scrollWrapper && scrollWrapper.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollWrapper.current;
            setScrollDisabled({
                left: scrollLeft <= 0,
                right: scrollLeft + clientWidth >= scrollWidth - 1,
            });
        }
    };

    const scroll = (direction) => {
        if (scrollRef.current) {
            const scrollAmount = 315; // Adjust this value as needed
            if (direction === 'left') {
                scrollRef.current.scrollLeft -= scrollAmount;
            } else {
                scrollRef.current.scrollLeft += scrollAmount;
            }
            // Update button disabled state after scrolling
            setTimeout(() => {
                updateScrollButtons(scrollRef);
            }, 100);
        }
    };

    return (
        <>
            {
                product && product.length > 0 &&
                <>
               
                        <ScrollableBodyContainer>
                            
                        <div className={styles.matchingProductContainer}>
                            <p className={styles.matchingProductTitle}>Related Products</p>
                            <div className={styles.videosScrollHeader}>
                                <GenericScrollHeader
                                    // headerText='Feature Innovation'
                                    leftOnClick={() => scroll('left')}
                                    rightOnClick={() => scroll('right')}
                                    leftDisabled={scrollDisabled.left}
                                    rightDisabled={scrollDisabled.right}
                                />
                            </div>
                            <div className={styles.scrollWrapper}>
                                <div ref={scrollRef} className={styles.container}>

                                    {product.map((p, idx) => (
                                        <span className={styles.productCard} key={idx}>
                                        <ProductCard key={idx} product={p} />
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                        </ScrollableBodyContainer>
                   
                </>
            }

        </>


    );
}
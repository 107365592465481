import styles from "./DataShowcase.module.css";
import UserDataTiles from "../../../PortalComponents/DisplayData/UserDataTiles";
import ProductGuideTrafficCompare from "../../../PortalComponents/DisplayData/ProductGuideTrafficCompare";
import ProductGuideCityUsageLeaderboard from "../../../PortalComponents/DisplayData/ProductGuideCityUsageLeaderBoard"

const DataShowcase = () => {
  return (
      <section className={styles.dataSection}>
        <UserDataTiles />
        <ProductGuideCityUsageLeaderboard />
        <ProductGuideTrafficCompare/>
      </section>
  );
}

export default DataShowcase;
import TheButton from "../../../../Shared/Components/UI/TheButton/TheButton";
import { useReducer, useEffect } from 'react';
import styles from './DynamicResourceFormSection.module.css';
import { DynamicTextInput } from "./DynamicTextInput";
import { StaticImageUpload } from "../../../../Shared/Components/UI/FormElements/ImageUpload/StaticImageUpload";
import { CountBubble } from "../../../../components/CountBubble/CountBubble";
const sectionReducer = (state, action) => {

    switch (action.type) {
        case 'INITIALIZE_SECTIONS':
            return action.sections.map(section => ({
                ...section,
                resourceQrCodeImageEN: section.resourceQrCodeImageEN || '',
                resourceQrCodeImageFR: section.resourceQrCodeImageFR || ''
            })) || [];

        case "ADD_SECTION":
            return [
                ...state,
                {
                    id: state.length,
                    resourceTitleEN: '',
                    resourceUrlEN: '',
                    resourceQrCodeImageEN: '',

                    resourceTitleFR: '',
                    resourceUrlFR: '',
                    resourceQrCodeImageFR: ''
                }
            ];
        case "REMOVE_SECTION":
            return state.filter((_, index) =>
                index !== action.index);

        case "UPDATE_TITLE":
            return state.map((section, index) =>
                index === action.index ? {
                    ...section,
                    [action.language === 'EN' ? 'resourceTitleEN' : 'resourceTitleFR']: action.value
                    // ...section, 
                    // resourceTitleEN: action.value, 
                    // resourceTitleFR: action.value 
                } : section
            );
        case 'UPDATE_URL':
            return state.map((section, index) =>
                index === action.index ? {
                    ...section,
                    [action.language === 'EN' ? 'resourceUrlEN' : 'resourceUrlFR']: action.value
                    // resourceUrlEN: action.value,
                    // resourceUrlFR: action.value
                } : section
            );
        case 'ADD_IMAGE':
            // Update the image URL of a specific section.
            return state.map((section, index) =>
                index === action.index
                    ? {

                        ...section,
                        [action.language === 'EN' ? 'resourceQrCodeImageEN' : 'resourceQrCodeImageFR']: action.file
                        // resourceQrCodeImageEN: action.file || section.resourceQrCodeImageEN,
                        // resourceQrCodeImageFR: action.file || section.resourceQrCodeImageFR
                    }
                    : section
            );

        default:
            return state;

    }
};

export const ResourceFormSection = ({ initialSections = [], onSectionsChange }) => {
    const [sections, dispatch] = useReducer(sectionReducer, initialSections);
    useEffect(() => {
        if (initialSections.length > 0) {
            const mappedSections = initialSections.map(section => ({
                ...section,
                resourceQrCodeImageEN: section.resourceQrCodeImageEN || '',
                resourceQrCodeImageFR: section.resourceQrCodeImageFR || ''
            }));
            dispatch({ type: 'INITIALIZE_SECTIONS', sections: mappedSections });
        }
    }, [initialSections]);

    useEffect(() => {
        if (onSectionsChange) {
            onSectionsChange(sections);
        }
    }, [sections, onSectionsChange]);

    const handleAddSection = () => dispatch({ type: 'ADD_SECTION' });

    const handleRemoveSection = (index) => dispatch({ type: 'REMOVE_SECTION', index });
    const handleTitleChange = (index, language, value) => dispatch({ type: 'UPDATE_TITLE', index, language, value });

    const handleUrlChange = (index, language, value) => dispatch({ type: 'UPDATE_URL', index, language, value });

    // const handleTitleChange = (index, value) => dispatch({ type: 'UPDATE_TITLE', index, value });

    // const handleUrlChange = (index, value) => dispatch({ type: 'UPDATE_URL', index, value });

    const handleImageUpload = (index, language, files) => {

        if (!files || files.length === 0) {
            return;
        }
        const file = files[0];
        dispatch({ type: 'ADD_IMAGE', index, language, file });
        // dispatch({ type: 'ADD_IMAGE', index, file });
    };

    return (
        <div className={styles.resourceFormSectionContainer}>
            <p className={styles.resourceFormTitle}>Specification Resources</p>
            <p className={styles.resourceFormSubtitle}>Create a custom resource by adding a new group. It must contain the title, URL and QR code. Title is what will show on button, ie. Owner’s Manual or Accessory Guide. QR code file must be a JPEG, PNG or WEBP, max size 2MB.</p>
            <p></p>
            <div className={styles.mainContainer}>
                <div className={styles.headerWrapper}>
                    <div className={styles.headerButtonWrapper}>
                        <button className={styles.resourceFormButton} type="button" onClick={handleAddSection}> Add Group</button>
                        {/* <TheButton onClick={handleAddSection} buttonStyleType="primary">
                            Add Group
                        </TheButton> */}
                        {/* {
                            sections.length === 0 &&
                            <Button onClick={handleAddSection} buttonStyleType="primary">
                                Add Group
                            </Button>
                        } */}

                    </div>
                    <div className={styles.headerCountWrapper}>
                        <h1>Groups Added</h1>
                        {/* <PageText type="pageTertiaryTitle">Groups Added</PageText> */}
                        <CountBubble itemCount={sections.length} />
                    </div>
                </div>
                <div className={styles.bodyWrapper}>
                    {sections.map((section, index) => (
                        <div key={index} className={styles.resourceGroupWrapper}>
                            <div className={styles.groupHeader}>
                                <div className={styles.groupHeaderTitle}>
                                    <h1>Resource Group {`${index + 1}`}</h1>
                                    {/* <PageText type="pageSubtitle">Resource Group {`${index + 1}`}</PageText> */}
                                </div>
                            </div>
                            <div className={styles.groupFormElementsWrapper}>
                                <div className={styles.formElementsInputs}>
                                    <div className={styles.formElement}>
                                        <p className={styles.formElementLabel}>Resource Title EN</p>
                                        <DynamicTextInput
                                            id={`resourceTitleEN-${index}`}
                                            name={`resourceTitleEN-${index}`}
                                            labelName="Resource Title EN"
                                            value={section.resourceTitleEN}
                                            // onChange={(e) => handleTitleChange(index, e.target.value)}
                                            onChange={(e) => handleTitleChange(index, 'EN', e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.formInputBreak}/>
                                    <div className={styles.formElement}>
                                        <p className={styles.formElementLabel}>Resource Title FR</p>
                                        <DynamicTextInput
                                            id={`resourceTitleFR-${index}`}
                                            name={`resourceTitleFR-${index}`}
                                            labelName="Resource Title FR"
                                            value={section.resourceTitleFR}
                                            onChange={(e) => handleTitleChange(index, 'FR', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className={styles.formElementsInputs}>

                                    <div className={styles.formElement}>
                                        <p className={styles.formElementLabel}>Resource URL EN</p>

                                        <DynamicTextInput
                                            id={`resourceUrlEN-${index}`}
                                            name={`resourceUrlEN-${index}`}
                                            labelName="Resource Url EN"
                                            value={section.resourceUrlEN}
                                            type="textinput"
                                            onChange={(e) => handleUrlChange(index, 'EN', e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.formInputBreak}/>
                                    <div className={styles.formElement}>
                                        <p className={styles.formElementLabel}>Resource URL FR</p>

                                        <DynamicTextInput
                                            id={`resourceUrlFR-${index}`}
                                            name={`resourceUrlFR-${index}`}
                                            labelName="Resource Url FR"
                                            value={section.resourceUrlFR}
                                            type="textinput"
                                            onChange={(e) => handleUrlChange(index, 'FR', e.target.value)}
                                        />
                                    </div>

                                </div>
                                <div className={styles.formElementsInputs}>
                                    <div className={styles.formElement}>
                                        <p className={styles.formElementLabel}>Resource Qr Code EN</p>
                                        <StaticImageUpload
                                            previewUrl={
                                                typeof section.resourceQrCodeImageEN === 'string'
                                                    ? section.resourceQrCodeImageEN
                                                    : URL.createObjectURL(section.resourceQrCodeImageEN)
                                            }
                                            // handleFileChange={(e) => handleImageUpload(index, e.target.files)}
                                            handleFileChange={(e) => handleImageUpload(index, 'EN', e.target.files)}

                                        />
                                    </div>
                                    <div className={styles.formInputBreak}/>
                                    <div className={styles.formElement}>
                                        <p className={styles.formElementLabel}> Resource Qr Code FR</p>

                                        <StaticImageUpload
                                            previewUrl={
                                                typeof section.resourceQrCodeImageFR === 'string'
                                                    ? section.resourceQrCodeImageFR
                                                    : URL.createObjectURL(section.resourceQrCodeImageFR)
                                            }
                                            // handleFileChange={(e) => handleImageUpload(index, e.target.files)}
                                            handleFileChange={(e) => handleImageUpload(index, 'FR', e.target.files)}

                                        />
                                    </div>


                                </div>


                            </div>

                            <div className={styles.groupFooter}>
                                <div className={styles.footerButtonWrapper}>
                                    <button className={styles.removeGroupButton} type="button" onClick={() => handleRemoveSection(index)}>Remove Group {`${index + 1}`}</button>
                                    {/* <TheButton styleName="red" animated="true" onClick={() => handleRemoveSection(index)}>
                                        Remove Group {`${index + 1}`}
                                    </TheButton> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )

}
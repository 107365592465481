// import { Button } from '../../Button/Button'
// import { IconComponent } from '../../Icon/IconComponent'
// import { PageText } from '../../Text/Text'
import TheIcon from '../../TheIcon/TheIcon'
import styles from './StaticImageUpload.module.css'
import { useRef } from 'react'
import TheButton from '../../TheButton/TheButton'

export const StaticImageUpload = ({
    iconType = 'uploadArrow',
    itemName = 'Media',
    previewUrl,
    // selectedFile,

    handleFileChange,
}) => {



    const filePickerRef = useRef();

    const pickImageHandler = () => {
        filePickerRef.current.click();
    }

    // TESTING
    // Check if previewUrl includes valid image paths before rendering
    const shouldRenderPreview = previewUrl && (
        previewUrl.includes('images/') ||
        previewUrl.includes('/images/') ||
        previewUrl.includes('blob')
    );

    return (
        <div className={styles.imageUploadContainer}>
            

            {
                // previewUrl || selectedFile ? (
                // previewUrl  ? (
                shouldRenderPreview ? (
                    <div className={styles.imageUploadBodyPreview}>
                        <div className={styles.imageWrapper}>
                            <img alt='image preview' src={previewUrl} />
                            {/* <img alt='image dish' src={previewUrl ? previewUrl : selectedFile} /> */}
                        </div>
                        <div className={styles.imageUploadFooterPreview}>
                            <div className={styles.bodyActionContainer}>
                                <input
                                    ref={filePickerRef}
                                    style={{ display: 'none' }}
                                    type="file"
                                    accept=".jpg, .png, .jpeg, .webp"
                                    onChange={handleFileChange} />
                                <div className={styles.buttonWrapper}>
                                    <button type='button' onClick={pickImageHandler} >Change selection</button>
                                    {/* <TheButton onClick={pickImageHandler} buttonStyleType='secondary'>Change selection</TheButton> */}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={styles.imageUploadBody}>
                       
                        <div className={styles.bodyText}>
                            <div className={styles.bodyTitle}>
                                {/* <PageText>Upload your image</PageText> */}
                                <p>Upload File</p>
                            </div>
                          
                        </div>
                        <div className={styles.bodyActionContainer}>
                            <input
                                ref={filePickerRef}
                                style={{ display: 'none' }}
                                type="file"
                                accept=".jpg, .png, .jpeg, .webp"
                                onChange={handleFileChange} />
                            <div className={styles.buttonWrapper}>
                            <button type='button' onClick={pickImageHandler} >Upload</button>
                                {/* <TheButton onClick={pickImageHandler} buttonStyleType='secondary'>Upload image</TheButton> */}
                            </div>
                        </div>
                    </div>
                )
            }

           
        </div>
    )

}
import { motion } from "framer-motion";
import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LanguageContext } from "../../../../Context/Language.Context";
import styles from "./SearchInput.module.css";
import TheIcon from "../../../Components/UI/TheIcon/TheIcon";


const MobileSearchInput = ({
  onChange,
  icon,
  onIconClick,
}) => {

  const { translatedText } = useContext(LanguageContext);

  return (
    <>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        className={styles.textInputWrapper}
      >
        <div className={styles.searchInputBox}>
          <input
            type="search"
            placeholder={`${translatedText.searchPlaceholder} e.x. WKEX300HBA`}
            onChange={onChange}
          />
          {icon && (
            <span onClick={onIconClick} className={styles.searchIcon}>
              <TheIcon name={icon} />
            </span>
            // <FontAwesomeIcon
            //     icon={icon}
            //     className={styles.searchIcon}
            //     onClick={onIconClick}
            // />
          )}
        </div>
      </motion.div>
    </>
  );
};

export default MobileSearchInput;

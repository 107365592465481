import styles from "./NavigationLinks.module.css";
import { NavLink } from "react-router-dom"
import { ExternalLink } from "../../../../Utils/link-helper";


export const ExternalNavigationLinks = ({ linkText, to, key }) => {
    console.log(to)
    return <ExternalLink href={to}>{linkText}</ExternalLink>;
}


import styles from '../ResourcesPages.module.css'
import FEATURE_DEFINITIONS from "../../../Data/FeatureDefinitions.json"
import { useEffect, useState, useContext } from 'react';
import { LanguageContext } from '../../../../Context/Language.Context';
import { usePopUpAnimation } from '../../../../Hooks/AnimationHook/UseAnimiationHook';
import featureDefintionsBackgroundImage from '../../../Theme/Product/CategoryImages/kitchen-3.webp';
import { motion } from 'framer-motion';
import HeroContainer from '../../../Components/UI/HeroContainer/HeroContainer';
import SectionTextContainer from '../../../Components/UI/SectionTextContainer/SectionTextContainer';
import PageWrapperTemplate from '../../../Components/UI/Templates/PageWrapperTemplate';
import BodyContainer from '../../../Components/UI/BodyContainer/BodyContainer';
import TheIcon from '../../../Components/UI/TheIcon/TheIcon';
import SectionContainer from '../../../Components/UI/SectionContainer/SectionContainer';


const DefinitionsPage = () => {
   const animationProps = usePopUpAnimation();
   // Initial state is now empty object.
   const [showSection, setShowSection] = useState({})

   const toggleDefinition = (definition) => {
      setShowSection(prevState => ({
         ...prevState,
         [definition]: !prevState[definition]
      }));

   };

   const [groupedData, setGroupedData] = useState({});
   const { language, translatedText } = useContext(LanguageContext);

   // Helper function to create initial state.
   const createInitialState = (groupedData) => {
      const initialState = {};
      for (const key in groupedData) {
         initialState[key] = false;
      }
      return initialState;
   }

   useEffect(() => {
      const groupByProductType = FEATURE_DEFINITIONS.reduce((acc, curr) => {
         const productTypeKey = language === "EN" ? "product-type-en" : "product-type-fr";
         const productType = curr[productTypeKey];

         if (!acc[productType]) {
            acc[productType] = [];
         }
         acc[productType].push(curr);
         return acc;
      }, {});

      setGroupedData(groupByProductType);
      setShowSection(createInitialState(groupByProductType)); // Set the showSection state
   }, [language]);

   const getFeatureDefinition = (definition, lang) => {
      return lang === "EN" ? definition["definition-en"] : definition["definition-fr"];
   };

   const getFeatureTitle = (definition, lang) => {
      return lang === "EN" ? definition["feature-en"] : definition["feature-fr"];
   };

   const getPageTitle = (lang) => {
      return lang === "EN" ? "Feature Definitions" : "Définitions des caractéristiques";
   };

   return (
      <>
      {/* {!groupedData ?
            (<Loader />) :
            ( */}
             
                  <PageWrapperTemplate
                    marginTop='14rem'
                  >
                  <BodyContainer>
                     <HeroContainer
                   
                        backgroundImage={featureDefintionsBackgroundImage}
                        title={ getPageTitle(language)}
                     />
                     {/* <TextBox
                      subtitle="Resources"
                      title="Explore Feature Definitions"
                      bodyText="This is your go-to destination for a quick and informative overview of LG's cutting-edge technology and innovations. You play a pivotal role in guiding customers through our range of products, and we're here to equip you with the knowledge you need to provide exceptional customer experiences. Explore our intuitive and advanced features such as AIDD™, QuadWash® and more. Our innovative solutions are designed to enhance convenience, efficiency, and overall performance."
                     /> */}
                      <SectionContainer>
                     <SectionTextContainer
                        subtitleText={translatedText.resources}
                        titleText={translatedText.exFeatureDefinitions}
                        descriptionText={translatedText.describeFeatureDefinitions}
                        // descriptionText2="You play a pivotal role in guiding customers through our range of products, and we're here to equip you with the knowledge you need to provide exceptional customer experiences. Explore our intuitive and advanced features such as AIDD™, QuadWash® and more. Our innovative solutions are designed to enhance convenience, efficiency, and overall performance."
                     />
                   </SectionContainer>
                        <section className={styles.definitionsWrapper}>
                           {Object.entries(groupedData).map(([productType, definitions], index) => {
                              return (
                                 <div key={index} className={styles.mainContentWrapper}>
                                    <div className={styles.productTypeHeader} onClick={() => toggleDefinition(productType)}>
                                       <motion.p
                                        {...animationProps}
                                          className={styles.productTypeText}
                                          id={productType}>
                                          {productType}
                                       </motion.p>
                                       {
                                          showSection[productType] !== true
                                             ? <TheIcon name='expand' />
                                             : <TheIcon name='close' />
                                       }
                                    </div>
                                    {showSection[productType] &&
                                       <motion.main {...animationProps} className={styles.featureDefinitionsWrapper}>
                                          {definitions.map((definition, idx) => (
                                             <div key={idx} className={styles.featureDefinitionsRow}>
                                                <p  onClick={() => toggleDefinition(productType)} className={styles.featureTitleText}>
                                                   {getFeatureTitle(definition, language)}
                                                </p>
                                                <div>
                                                   <p className={styles.definitionText}>
                                                      {getFeatureDefinition(definition, language)}
                                                   </p>
                                                </div>
                                             </div>
                                          ))}
                                       </motion.main>}
                                 </div>
                              );
                           })}
                        </section>
                     </BodyContainer>
                  </PageWrapperTemplate>
         
            {/* )
      } */}
      </>
   );
};

export default DefinitionsPage;

import { useState, useContext, useEffect } from "react";
import { useHttpClient } from "../../../Hooks/HttpHook/http-hook";

import CustomModal from "../../../Shared/Components/Modals/CustomModal/CustomModal";
import { AdminContext } from "../../../Context/Admin.Context";
import { AuthContext } from "../../../Context/Auth.Context";
import { RoutingContext } from "../../../Context/Routing.Context";
// import Loader from "../../../Shared/Components/Loaders/Loader";
// import Button from "../../../Shared/Components/Buttons/Buttons";
import SelectInput from "../../../Shared/Components/UI/FormElements/Select/Select";
import {jwtDecode} from "jwt-decode";
import PortalForm from "../../PortalComponents/FormElements/PortalForm";
import PortalFormRow from "../../PortalComponents/FormElements/PortalFormRow";
import Modal from "../../../Shared/Components/UI/TheModals/TheModal";
import TheButton from "../../../Shared/Components/UI/TheButton/TheButton";
import { useNavigate } from "react-router-dom";
import styles from "../UpdateUserStatus/PortalUserStatusFormPage.module.css"
const PortalUpdateUserRoleFormPage = () => {
    const redirect = useNavigate();
    const { token } = useContext(AuthContext);
    const decodedToken = token ? jwtDecode(token) : null;
    const { sendRequest } = useHttpClient();
    const { setIsActiveSection, isPortalActionState, setIsPortalDataState } = useContext(AdminContext);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showCustomModal, setShowCustomModal] = useState(false);
    const [accountRole, setAccountRole] = useState('');
    const [loadedUser, setLoadedUser] = useState(null);
    const { setIsRoutingState, isRoutingState } = useContext(RoutingContext)
    const [error, setError] = useState();
    // const userId = isEditingUser;
    const userId = isPortalActionState?.editingUser;

    const defaultModal = {
        show: false,
        title: '',
        message: '',
        errorList: [],
        actionText: 'Close',
        wasSuccessful: false,
        onConfirm: null,
        onCancel: null,
        onClose: null,
        cancelText: '',
        confirmText: '',
    }
    const [modalInfo, setModalInfo] = useState(defaultModal);

    const handleAccountRole = (event) => {
        setAccountRole(event.target.value);
    };

    // useEffect(() => {
    //     const fetchRequest = async () => {
    //         try {
    //             const data = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/user/${userId}`)
    //             setLoadedUser(data.user)
    //         } catch (err) { }
    //     }
    //     fetchRequest();
    // }, [sendRequest, userId]);
    useEffect(() => {
        const fetchRequest = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/${userId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
    
                if (!response.ok) {
                    throw new Error('Failed to fetch user data.');
                }
    
                const data = await response.json();
                setLoadedUser(data.user);
                // console.log(data);
            } catch (err) {
                console.error("Error fetching user:", err);
            }
        }
    
        fetchRequest();
    }, [sendRequest, userId]);

    
    
    
    
    
    
    


    const roleChangeSuccessHanlder = () => {
        setModalInfo({ ...modalInfo, show: false });
        setIsActiveSection(1);
        redirect('/admin')
    }

    const handleUpdateMyUserRole = (event) => {

        event.preventDefault();
        const hasChanges = (
            accountRole !== loadedUser.role

        );

        if (!hasChanges || accountRole === '') {
            setModalInfo({
                show: true,
                title: 'Nothing to update',
                message: `No changes were made. Update not required.`,
                errorList: [],
                confirmText: 'Go back',
                onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            })
            // alert('No changes were made.');
            return;
        }
        setModalInfo({
            show: true,
            title: 'Are you sure you want to make changes?',
            message: `You are about to change the user's role. This could affect their ability to use the website. Please confirm.`,
            confirmText: "Confirm",
            onConfirm: userRoleUpdateHandler,
            cancelText: "Cancel",
            onCancel: () => setModalInfo({ ...modalInfo, show: false }),
            onClose: () => setModalInfo({ ...modalInfo, show: false }),
        });
        // setShowConfirmationModal(true);
    }

    const userRoleUpdateHandler = async (event) => {
        event.preventDefault();
        setIsRoutingState(prevState => ({
            ...prevState,
            isLoading: true
        }))
        let errorMessage = [];
        if (!accountRole) errorMessage.push('Status missing or invalid.');


        if (errorMessage.length > 0) {
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false
            }))
            setModalInfo({
                show: true,
                title: 'Change request failed',
                message: `Please fix the error(s) below`,
                errorList: errorMessage,
                confirmText: 'Try again',
                onCancel: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });
            // setIsLoading(false);
            // setShowCustomModal(true);
            // setError({
            //     title: 'Role not updated',
            //     message: `Please fix the following errors:`,
            //     errorList: errorMessage
            // });
            return;
        }
        try {

            const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/edit-user-role/${userId}`,
                'PATCH',
                JSON.stringify({
                    role: accountRole,
                    adminId: decodedToken.userId,
                }),
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            );
            // console.log(response)
            if (response.message === 'User role updated.') {
                setIsRoutingState(prevState => ({
                    ...prevState,
                    isLoading: false
                }))

                // setIsPortalDataState(prevState => ({
                //     ...prevState,
                //     allUsers:
                // }))
                setIsPortalDataState(prevState => ({
                    ...prevState,
                    allUsers: prevState.allUsers?.map(user => 
                        user._id === userId ? response.updatedUser : user
                    )
                }));
                // setIsPortalDataState(prevState =>
                //     prevState.map(user =>
                //         user._id === userId ? response.updatedUser : user
                //     ))
                setModalInfo({
                    show: true,
                    title: 'Role change complete',
                    message: 'The user\'s role has been changed successfully!',
                    errorList: [],
                    confirmText: 'Close',
                    wasSuccessful: true,
                    onConfirm: roleChangeSuccessHanlder,
                    onClose: roleChangeSuccessHanlder
                });

            }
            if (!response.message === 'User role updated.') {
                throw new Error(response.message);
            }
            // setUpdateTheUser(prevState => !prevState)
            // if (response === 200) {

            //     // setActiveSection(6);
            //     setActiveSection(1);

            // }
            // if (!response.ok) {
            //     throw new Error(response.message);
            // }


        } catch (error) {
            // setIsLoading(false)
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false
            }))
            setModalInfo({
                show: true,
                title: 'Something went wrong',
                message: 'The user\'s role could not be changed. Please contact an administrator for assistance.',
                errorList: [],
                confirmText: 'Try again',
                onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });
            // setShowCustomModal(true);
            // setError({
            //     title: "Result",
            //     message: error.message,
            //     errorList: null
            // });
            // setActiveSection(6)

        } finally {
            // setIsLoading(false);
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false
            }))
        }

    }
    const closeModal = () => {
        setShowCustomModal(false);
    };

    return (
        <>
            <Modal
                icon="true"
                iconName="close"
                styleName="lightIconButton"
                iconOnClick={modalInfo.onClose}
                show={modalInfo.show}
                title={modalInfo.title}
                message={modalInfo.message}
                errorList={modalInfo.errorList}
                closeOnBackdropClick={false}
                actionText={modalInfo.actionText}
                actionBox={
                    <>
                        {modalInfo.onConfirm && (
                            <span onClick={modalInfo.onConfirm}>
                                <TheButton styleName="red" animated="true">
                                    {modalInfo.confirmText}
                                </TheButton>
                            </span>
                        )}
                        {modalInfo.onCancel && (
                            <span onClick={modalInfo.onCancel}>
                                <TheButton styleName="white" animated="true">
                                    {modalInfo.cancelText}
                                </TheButton>
                            </span>
                        )}
                    </>
                }
            />
            <Modal
                show={showConfirmationModal}
                title="Confirmation Required"
                message="This action will change the user's role. Please confirm."
                onConfirm={userRoleUpdateHandler}
                onCancel={() => {
                    setShowConfirmationModal(false);
                }}
            />

            {showCustomModal && (
                <CustomModal
                    show={showCustomModal}
                    title={error.title}
                    message={error.message}
                    errorList={error.errorList}
                    closeHandler={closeModal}
                />
            )}


            <PortalForm
                title="Manage User Roles"
                subtitle="Admin Portal"
                onSubmit={handleUpdateMyUserRole}
            >
                <div className={styles.userInfoWrapper}>
                    <p className={styles.userInfoHeader}>Current Role<span className={styles.userInfoDataText}>{loadedUser?.role}</span></p>
                </div>
                <PortalFormRow>
                    <SelectInput
                        id="role"
                        name="role"
                        value={accountRole}
                        requireAsterix

                        labelName="Account Role"
                        onChange={handleAccountRole}
                        options={[
                            { value: "", label: "change role", disabled: true },
                            { value: "user", label: "user" },
                            { value: "admin", label: "Administrator" },
                            { value: "superAdmin", label: "Super Administrator" },

                        ]}
                    />
                </PortalFormRow>
                <TheButton
                    styleName="red"
                    animated="true"
                >
                    Change Role
                </TheButton>
                {/* <Button type="submit" styleName="black"></Button> */}
            </PortalForm>
            {/* )
            } */}

        </>
    )

}

export default PortalUpdateUserRoleFormPage;
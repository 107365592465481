import React, { useContext } from 'react';
import { RetailerContext } from "../../../../Context/Retailer.Context";

import { SFWProductsContext } from '../../../../Context/CategoryContext';
import { LanguageContext } from '../../../../Context/Language.Context';
import { motion } from 'framer-motion';

import CategoryCard from "../../ProductElements/Cards/CategoryCard"
import styles from "./CategoryPreview.module.css"


const CategoryPreview = ({ categoryName, categoryDescription }) => {
    const { filteredProductsByCategory } = useContext(SFWProductsContext);
    const { isHomeDepotApp } = useContext(RetailerContext)


    const { language } = useContext(LanguageContext);

    // Create a flat array of all products
    // const allProducts = Object.values(productsByCategory).flat();
    const allProducts = Object.values(filteredProductsByCategory).flat();

    // Filter products for the current category and language
    const products = allProducts.filter(product => language === "FR" ? product.categoryFR === categoryName : product.categoryEN === categoryName);

    if (!products.length) {
        return null;
    }
    //DISPLAY SUBCAT BY ORDER 1/2
    // const subcategoryOrder = {
    //     "LG Studio": [
    //         "Refrigeration",
    //         "Built-in Cooking",
    //         "Free-standing Cooking",
    //         "Ventilation",
    //         "Dishwashers",
    //         "Laundry",

    //         "Cuisson intégr"
    //     ],
    //     "Laundry": [
    //         "Front Load",
    //         "WashTower",
    //         "Top Load",
    //         "Specialty Laundry",
    //         // "Styler",
    //         "Chargement frontal",
    //         "Chargement vertical",
    //         "Appareils spécialisés",

    //     ],
    //     "Refrigeration": [
    //         "36\" French Door Standard Depth",
    //         "36\" French Door Counter Depth",
    //         "33\" French Door Standard Depth",
    //         "33\" French Door Counter Depth",
    //         "30\" French Door",
    //         "Top and Bottom Mount",
    //         "Side-by-Side",
    //         "Column",
    //         "Kimchi",

    //     ],
    //     "Cooking": [
    //         "Electric Slide-in Ranges",
    //         "Cuisinières électriques encastrables",
    //         "Cuisinières encastrable  électriques",
    //         "Electric Free-standing Ranges",
    //         "Induction Ranges",
    //         "Gas Ranges",
    //         "Wall Ovens",
    //         "Cooktops",
    //         "Over-the-Range Microwaves",
    //         "Countertop Microwaves",
    //     ],
    //     "Dishwasher": [
    //         "Built-in"
    //     ],

    // };
    const subcategoryOrderEN = {
        "LG Studio": [
            "Refrigeration",
            "Built-in Cooking",
            "Free-standing Cooking",
            "Ventilation",
            "Dishwashers",
            "Laundry",
        ],
        "Laundry": [
            "Front Load",
            "WashTower",
            "Top Load",
            "Specialty Laundry",
            "Accessories" // NEW
            // "Styler",

        ],
        "Refrigeration": [
            "36\" French Door Standard Depth",
            "36\" French Door Counter Depth",
            "33\" French Door Standard Depth",
            "33\" French Door Counter Depth",
            "30\" French Door",
            "Top and Bottom Mount",
            "Side-by-Side",
            "Column",
            "Kimchi",

        ],
        "Cooking": [
            "Electric Slide-in Ranges",
            "Electric Free-standing Ranges",
            "Induction Ranges",
            "Gas Ranges",
            "Wall Ovens",
            "Cooktops",
            "Over-the-Range Microwaves",
            "Countertop Microwaves",
        ],
        "Dishwasher": [
            "Built-in"
        ],

    };

    const subcategoryOrderFR = {
        "LG Studio": [
            "Réfrigérateur",
            "Cuisson intégré",
            "Cuisson autonome",
            "Ventilation",
            "Lave-vaisselles",
            "Lessive",
        ],
        "Lessive": [
            "Chargement frontal",
            "WashTower",
            "Chargement vertical",
            "Appareils spécialisés",
            // "Styler"
        ],
        "Réfrigérateur": [
            "Porte à deux battants profondeur standard de 36 po",
            "Porte à deux battants profondeur de comptoir de 36 po",
            "Porte à deux battants profondeur standard de 33 po",
            "Porte à deux battants profondeur de comptoir de 33 po",
            "Porte à deux battants de 30 po",
            "Congélateur en haut et bas",
            "Côte-à-côte",
            "Colonne",
            "Kimchi"
        ],
        "Cuisson": [
            "Cuisinière encastrable électrique",
            "Cuisinières encastrable électriques",
            "Cuisinières à inductions",
            "Cuisinière au gaz",
            "Four mural",
            "Surface de cuisson",
            "Four à micro-ondes à hotte intégrée",
            "Four à micro-ondes de comptoir",
        ],
        "Lave-vaisselle": [
            "Intégré"
        ],

    };

    //DISPLAY SUBCAT WITHOUT ORDER - FIRST PRODUCT IN ARRAY
    // const subcategories = Array.from(new Set(products.map((product) => language === 'FR' ? product.subcategoryFR : product.subcategoryEN)));
    // const subcategoryProducts = subcategories.map((subcategory) => {
    //     const productsInSubcategory = products.filter((product) => (language === 'FR' ? product.subcategoryFR : product.subcategoryEN) === subcategory);
    //     const firstProduct = productsInSubcategory[0];
    //     return {
    //         subcategory,
    //         product: firstProduct,

    //     };

    // });

    //DISPLAY SUBCAT BY ORDER 2/2
    // const subcategories = Array.from(new Set(products.map((product) => language === 'FR' ? product.subcategoryFR : product.subcategoryEN)));
    // const currentSubcategoryOrder = language === "FR" ? subcategoryOrderFR : subcategoryOrderEN;

    // const orderedSubcategoryProducts = currentSubcategoryOrder[categoryName]?.map((orderedSubcategory) => {
    //     return subcategoryProducts.find(({ subcategory }) => subcategory === orderedSubcategory);
    // }).filter(Boolean);

    // const subcategoryProducts = subcategories.map((subcategory) => {
    //     const productsInSubcategory = products.filter((product) => (language === 'FR' ? product.subcategoryFR : product.subcategoryEN) === subcategory);
    //     const firstProduct = productsInSubcategory[0];
    //     return {
    //         subcategory,
    //         product: firstProduct,
    //     };
    // });

    // Order the subcategory products according to the desired order
    // const orderedSubcategoryProducts = subcategoryOrder[categoryName]?.map((orderedSubcategory) => {
    //     return subcategoryProducts.find(({ subcategory }) => subcategory === orderedSubcategory);
    // }).filter(Boolean); // Filter out any undefined items in case there's a mismatch between orders and actual data

    // 1. Define `subcategories`
    const subcategories = Array.from(new Set(products.map((product) => language === 'FR' ? product.subcategoryFR : product.subcategoryEN)));

    // 2. Define `subcategoryProducts`
    const subcategoryProducts = subcategories.map((subcategory) => {
        const productsInSubcategory = products.filter((product) => (language === 'FR' ? product.subcategoryFR : product.subcategoryEN) === subcategory);
        const firstProduct = productsInSubcategory[0];
        return {
            subcategory,
            product: firstProduct,
        };
    });

    // 3. Define `currentSubcategoryOrder`
    const currentSubcategoryOrder = language === "FR" ? subcategoryOrderFR : subcategoryOrderEN;

    // 4. Define `orderedSubcategoryProducts`
    const orderedSubcategoryProducts = currentSubcategoryOrder[categoryName]?.map((orderedSubcategory) => {
        return subcategoryProducts.find(({ subcategory }) => subcategory === orderedSubcategory);
    }).filter(Boolean); // Filter out any undefined items in case there's a mismatch between orders and actual data

  
    return (
        <div className={styles.categoryPreviewCardWrapper}>
            {
                // subcategoryProducts.map(({ category, subcategory, product }) => (
                orderedSubcategoryProducts?.map(({ subcategory, product }) => (
                    <>
                        <motion.div
                            key={subcategory}
                            whileHover={{
                                scale: 1.02,
                                transition: {
                                    duration: .2,
                                },
                                boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.2)" // Added boxShadow
                            }}
                            whileTap={{
                                scale: 1,
                                transition: {
                                    duration: .3,
                                }
                            }}
                            transition={{ duration: 0.3 }} // smooth out the transition (optional)
                            className={styles.desktopCard}
                        >
                            <CategoryCard category={categoryName} subCategory={subcategory} product={product} />
                        </motion.div>
                        <motion.div

                            className={styles.mobileCard}
                        >
                            <CategoryCard category={categoryName} subCategory={subcategory} product={product} />
                        </motion.div>
                    </>
                ))
            }
        </div>
    );
};

export default CategoryPreview;

export const translatedText = {
        EN: {


                // ---------WIRE MESSAGING--------- // 

                scanQR: "Scan QR Code",

                missingEmail: 'Email missing or invalid!',
                missingPassword: 'Password missing or invalid!',
                emailPasswordMissing: 'Please provide email and password.',

                results: "Results",

                exploreHA: "Explore Home Appliances",
                // CATEGORIES
                homeAppliances: "Home Appliances",
                allCategoriesTitle: "Explore Product Categories",
                allCategoriesDescription: "Each category will also feature detailed information and videos about LG's unique innovations to help you promote our products while out on the sales floor.",
                range: "Range",
                compareRange: "Compare Range",
                cooking: "Cooking",
                compareCooking: "Compare Cooking",

                dishwasher: "Dishwasher",
                compareDishwasher: "Compare Dishwasher",

                microwave: "Microwave",
                laundry: "Laundry",
                compareLaundry: "Compare Laundry",

                refrigeration: "Refrigeration",
                compareRefrigeration: "Compare Refrigeration",
                lgStudio: "LG Studio",

                // LINKS
                products: "Products",
                allCategories: "All Categories",
                brochureBuilder: "Brochure Builder",

                //NAV HEADERS
                exclusiveOffers: "Exclusive Offers",
                warranties: "Warranties",


                // -- RESOURCE LINKS
                resources: "Resources",
                modelTransitions: "Model Transitions",
                exModelTransitions: "Explore Model Transitions",
                describeModelTransitions: "Stay up-to-date with the latest advancements in LG Home Appliances, ensuring you're well-equipped to guide customers through seamless product transitions. From built-in cooktops to laundry machines and more, we'll keep you informed about what's new on our new models versus the model it's replacing.",

                featureDefinitions: "Feature Definitions",
                exFeatureDefinitions: "Explore Feature Definitions",
                describeFeatureDefinitions: "This is your go-to destination for a quick and informative overview of LG's cutting-edge technology and innovations. You play a pivotal role in guiding customers through our range of products, and we're here to equip you with the knowledge you need to provide exceptional customer experiences. Explore our intuitive and advanced features such as AIDD™, QuadWash® Pro and more.",

                limitedWarranties: "Limited Warranties",
                exLimitedWarranties: "Explore Limited Warranties",
                describeLimitedWarranties: "We understand the significance of providing customers with peace of mind, which is why we offer tailored warranties for each product category, covering both parts and labour. Confidently present specific warranty details to customers to build trust and loyalty, knowing that LG Home Appliances are protected and reliable.",

                // -- RIGHTSLINKS
                allRightsReserved: "All Rights Reserved LG Home Appliances 2023",

                // -- WELCOME BANNER
                welcomeBannerLong: "Sign up and get exclusive access to the new Purchase Pro!",
                welcomeBannerShort: "for Purchase Pro!",
                // CTAS
                tryItNow: "Try it now!",
                ctaSeeDetails: "See Details",
                ctaDetails: "Details", //NEW
                ctaAddToList: "Add",
                ctaRemoveFromList: "Remove",
                viewAll: "View All",
                explore: "Explore",
                myAccount: "My Account",
                savedLists: "Saved Lists",
                profile: "Profile",
                signIn: "Sign in",
                signOut: "Sign out",
                signUp: "Sign up",
                createAccount: "Create Account",
                myAccount: "My Account",
                notRegistered: "Not registered?",
                alreadyRegistered: "Already registered?",
                send: "send",

                // FORMS
                password: "Password",
                resetPassword: "Reset Password",
                signUpNow: "Sign up now",
                signInNow: "Sign in now",
                firstName: "First name",
                lastName: "Last name",
                retailer: "Retailer",
                newPassword: "New password",
                confirmNewPassword: "Confirm new password",


                //TABLE
                productHeader: "Products",
                createdAt: "Created",
                lastUpdated: "Last Updated",
                listName: "List Name",
                listSubtitle: "manage your saved lists",





                // PURCHASE PRO

                yourProductList: "Your Product List",
                yourProductListIsEmpty: "Your product list is empty",
                searchPlaceholder: "Search by model",
                noResult: "No results!",
                clearList: "Clear list",
                save: "Save",
                print: "Print",
                list: "list",
                delete: "Delete",

                // CONFIRMATION MODALS
                areYouSureTitle: "Are you sure you want to clear your list?",
                clearListMessage: "This action will delete all products from your Product List. Please confirm to proceed.",
                cancel: "Go Back",
                deleteList: "Confirm",

                //EMAIL MODAL
                emailModalTitle: "Email your list",
                emailModalMessage: "Want to share your list via an email? It's simple. Just add the email address of the person you'd like to share your list with, and press send. That's it. Get started!",
                emailInputLabel: "Email recipient",
                emailSuccessMessage: "Email sent successfully!",
                emailFailedMessage: "Something went wrong. Your email was not sent.",
                // OUTPUT - FLASH

                // STEP-UP-CHARTS
                stepUpChart: "Step-up Charts",


                //NEW TRANS

                viewStepUpChart: "View Step-up Chart",


                welcomeBarTextLong: "Experience The Product List - LG's NEW premium sales tool to enhance every customer's buying journey. Add, Print, Present!",


                expInnovation: "Experience Innovation",
                avail: "Available",
                lgPg: "LG Product Guide",

                haSalesTool: "Home Appliances Information and Sales Tool",

                welcomePG: "Welcome to the LG Product Guide",

                totSales: "Your total-solution sales tool covering the complete line-up of LG Home Appliances.",

                dySalesTL: "The Product List is your dynamic LG sales tool",
                brHACard: "Browse the complete up-to-date line-up of LG Home Appliances and learn more from specs and videos.",

                brSUCard: "Compare products across key technology features, specifications, dimensions and more with LG Step-Up Charts.",

                brRECard: "Learn more about model transitions, feature innovations and warranties.",
                privacy: "Privacy",
                terms: "Terms of Use",
                // pL: "Product List",
                pL: "My Product List",
                support: "Support",

                introPL: "Introducing the Product List",

                descrPL: "As you help search for the perfect LG appliance suite with your customer for their home, you can now add those models to the Product List. Once their list is complete, you can print it off and hand it to the customer. The list includes all the relevant information a customer needs to help them make their buying decision.",

                tryPLBtn: "Try the Product List",

                searchPLHA: "Search Home Appliances",
                exploreRef: "Explore Refrigeration",
                exploreLau: "Explore Laundry",
                exploreDish: "Explore Dishwasher",
                exploreCook: "Explore Cooking",

                //PRODUCT FORMS
                specificationsTitle: "Specifications",
                stylishDesignTitle: "Stylish Design",
                innovationsTitle: "Innovations",
                matchingAddOnsTitle: "Matching Add-ons",
                organizationTitle: "Organization",
                performanceTitle: "Performance",
                optionalPanelsTitle: "Optional Panels",
                optionalTrimKitTitle: "Optional Trim Kit",
                lgStudioExclusiveTitle: "LG Studio Exclusive",


        },
        FR: {
                exploreHA: "Explorer Électroménagers",

                results: "Résultats",
                scanQR: "Scanner le code QR",
                //NEW TRANS
                avail: "Disponible",
                welcomeBarTextLong: "Découvrez la liste de produits - Le NOUVEL outil de vente LG qui améliora l'expérience d'achat de chaque client. Ajoutez, Imprimez, Présentez!",
                expInnovation: "Expérimenter l'innovation",
                lgPg: "Guide des produits LG",
                haSalesTool: "Outil de vente et d'information d'Électroménagers",
                totSales: "Votre outil de vente complet couvrant la gamme entière d'appareils électroménagers LG.",
                dySalesTL: "La liste de produits avec votre outil de vente dynamique de LG",
                welcomePG: "Bienvenue au Guide des Produits",
                brHACard: "Parcourez la gamme complète et à jour d'appareils électroménagers LG et apprenez-en davantage grâce aux fiches technique et aux vidéos.",
                brSUCard: "Comparez les produits en fonction des caractéristiques clés, des spécifications, des dimensions et bien plus encore avec les tableaux de catégories LG",
                brRECard: "Apprenez-en davantage sur les transitions de modèles, les innovations en matière de fonctionnalités et les garanties",
                descrPL: "Pendant que vous aidez votre client à trouver l’ensemble d’appareils LG idéal pour eux, vous pouvez désormais ajouter ces modèles à la liste des produits. Lorsque leur sélection sera complétée, vous pouvez l’imprimer et la remettre au client. La liste comprend toutes les informations pertinentes dont un client a besoin afin de l’aider à prendre sa décision d’achat",
                introPL: "Présentation de la liste des produits",
                tryPLBtn: "Essayez la liste des produits",
                privacy: "Confidentialité",
                terms: "Conditions d'utilisations",
                pL: "Liste des produits",
                support: "Soutien",
                searchPLHA: "Rechercher les appareils électroménagers.",
                viewStepUpChart: "Afficher le tableau de catégorie",
                exploreRef: "Explorer la réfrigération",
                exploreLau: "Explorer la lessive",
                exploreDish: "Explorer les lave-vaisselles",
                exploreCook: "Explorer la cuisson",
                delete: "Supprimez",
                // ---------WIRE MESSAGING--------- // 


                missingEmail: 'E-mail manquant ou invalide!',

                missingPassword: 'Mot de passe manquant ou invalide !',
                emailPasswordMissing: 'Veuillez fournir votre e-mail et votre mot de passe.',

                // CATEGORIES
                homeAppliances: "Électroménagers",
                allCategoriesTitle: "Explorer les catégories de produits",
                allCategoriesDescription: "Chaque catégorie comprendra également des informations détaillées et des vidéos sur les innovations uniques de LG pour vous aider à promouvoir nos produits lorsque vous êtes sur le point de vente.",

                range: "Cuisinière",
                cooking: "Cuisson",

                compareCooking: "Comparez la cuisine",

                dishwasher: "Lave-vaisselle",
                compareDishwasher: "Comparez la lave-vaisselle",


                microwave: "Micro-ondes",

                laundry: "Lessive",
                compareLaundry: "Comparez la Laundry",

                refrigeration: "Réfrigérateur",
                compareRefrigeration: "Comparez la réfrigération",
                lgStudio: "LG Studio",

                // LINKS
                products: "Des produits",
                allCategories: "Toutes les catégories",
                brochureBuilder: "Constructeur de brochures",

                //NAV HEADERS
                exclusiveOffers: "Offres exclusives",
                warranties: "Garanties",

                // -- RESOURCE LINKS
                resources: "Ressources",

                modelTransitions: "Modèles de Transitions",
                exModelTransitions: "Explorer les transitions de modèles",
                describeModelTransitions: "Restez au courant des dernières avancées en matière d'appareils électroménagers LG, en vous assurant d'être bien équipé pour guider les clients tout au long des transitions de produits. Des tables de cuisson encastrés aux machines à laver et plus encore, nous vous tiendrons informés des nouveautés de nos nouveaux modèles par rapport au modèle qu'ils remplacent.",


                featureDefinitions: "Définitions des fonctionnalités",
                exFeatureDefinitions: "Explorer les définitions des fonctionnalités",
                describeFeatureDefinitions: "C'est votre destination clé pour un aperçu rapide et informatif de la technologie et des innovations de pointe de LG. Vous jouez un rôle central en guidant les clients à travers notre gamme de produits et nous sommes là pour vous doter des connaissances dont vous avez besoin pour offrir des expériences client exceptionnelles. Découvrez nos fonctionnalités intuitives et avancées telles que AIDD™, QuadWash® Pro et plus encore.",


                limitedWarranties: "Garanties Limités",
                exLimitedWarranties: "Explorez les garanties limitées",
                describeLimitedWarranties: "Nous comprenons l'importance d'offrir à nos clients une tranquillité d'esprit, c'est pourquoi nous proposons des garanties sur mesure pour chaque catégorie de produits, couvrant à la fois les pièces et la main d'œuvre. Présentez en toute confiance les détails spécifiques de la garantie aux clients pour renforcer la confiance et la fidélité, sachant que les appareils électroménagers LG sont protégés et fiables.",



                // -- RIGHTSLINKS
                allRightsReserved: "Tout droits réservés LG Électroménagers 2023",

                // -- WELCOME BANNER
                welcomeBannerLong: "Inscrivez-vous et obtenez un accès exclusif au nouveau Buy Pro !",
                welcomeBannerShort: "pour Buy Pro GRATUIT aujourd'hui !",
                // CTAS
                tryItNow: "Essayez-le maintenant!",
                ctaSeeDetails: "Voir les détails",
                ctaDetails: "Détails", //NEW
                ctaAddToList: "Ajouter",
                ctaRemoveFromList: "Supprimer",
                viewAll: "Tout afficher",
                explore: "Explorer",
                savedLists: "Listes enregistrées",
                myAccount: "Mon compte",
                profile: "Profil",
                signIn: "Connectez-vous",
                signOut: "Se déconnecter",
                createAccount: "Créer un compte",
                myAccount: "Mon compte",
                notRegistered: "Non enregistré?",
                alreadyRegistered: "Déjà enregistré?",
                send: "envoyer",

                // FORMS

                password: "Mot de passe",
                resetPassword: "Réinitialiser le mot de passe",
                newPassword: "Nouveau mot de passe",
                confirmNewPassword: "Confirmer le nouveau mot de passe",
                signUpNow: "S'inscrire maintenant",
                signInNow: "Connectez vous maintenant",
                signUp: "S'inscrire",

                firstName: "Prénom",
                lastName: "Nom de famille",
                retailer: "détaillant",


                //TABLE
                productHeader: "Des produits",
                createdAt: "Créé",
                lastUpdated: "Dernière mise à jour",
                listName: "Nom de la liste",
                listSubtitle: "gérer votre liste sauvegardées",

                // PURCHASE PRO
                yourProductList: "Votre liste de produits",
                yourProductListIsEmpty: "Votre liste de produits est vide.",
                searchPlaceholder: "Recherche par modèle",
                noResult: "Aucun résultat!",
                clearList: "Effacer la liste",
                save: "Sauvegarder",
                print: "Imprimez",
                list: "liste",



                // CONFIRMATION MODALS
                areYouSureTitle: "Es-tu sûr de ça?",
                clearListMessage: "Cette action supprimera tous les produits de votre liste Buy Pro. Êtes-vous sur de vouloir continuer?",
                cancel: "Annuler",
                deleteList: "Supprimer la liste",

                //EMAIL MODAL
                emailModalTitle: "Envoyez votre liste par e-mail",
                emailModalMessage: "Vous souhaitez partager votre liste par e-mail ? C'est simple. Ajoutez simplement l'adresse e-mail de la personne avec qui vous souhaitez partager votre liste et appuyez sur envoyer. C'est ça. Commencer!",
                emailInputLabel: "Destinataire du courriel",
                emailSuccessMessage: "E-mail envoyé avec succès!",
                emailFailedMessage: "Quelque chose s'est mal passé. Votre email n'a pas été envoyé.",


                // STEP-UP-CHARTS
                stepUpChart: "Tableaux de Catégories",


                //PRODUCT FORMS
                specificationsTitle: "Caractéristiques",
                stylishDesignTitle: "Conception élégante",
                innovationsTitle: "Nouveautés",
                matchingAddOnsTitle: "Compléments correspondants",
                organizationTitle: "Organisation",
                performanceTitle: "Performance",
                optionalPanelsTitle: "Panneaux optionnels",
                optionalTrimKitTitle: "Kit de garniture en option",
                lgStudioExclusiveTitle: "Exclusivité LG Studio",


        },
};
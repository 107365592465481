
import styles from './GenericScrollHeader.module.css';
import { ScrollButtons } from '../Button/ScrollButtons';

export const GenericScrollHeader = ({
    headerText,
    leftDisabled,
    leftOnClick,
    rightDisabled,
    rightOnClick
}) => {

    return (
        <div className={styles.scrollBarContainer}>
            {
                headerText ?
                    <div className={styles.headerContainer}>
                        <div className={styles.headerTitleWrapper}>
                            <h1>{headerText}</h1>
                            {/* <PageText type='bodyTertiaryTitleBold'>{headerText}</PageText> */}
                        </div>
                    </div>
                    : null
            }

            <div className={styles.buttonContainer}>
                <ScrollButtons
                    leftDisabled={leftDisabled}
                    leftOnClick={leftOnClick}
                    rightDisabled={rightDisabled}
                    rightOnClick={rightOnClick}
                />
            </div>
        </div>
    );
}
import { createContext, useState, useContext, useEffect } from "react";
import { RoutingContext } from "./Routing.Context";


import {jwtDecode} from "jwt-decode";
import { AuthContext } from "./Auth.Context";

export const AdminContext = createContext({
    //INCLUDED
    // ------------------------- 
    // ACTIVE SECTION
    // ------------------------- 
    isActiveSection: [],
    setIsActiveSection: () => { },

    // ------------------------- 
    // PORTAL USER + ACTIONS
    // ------------------------- 
    isPortalActionState: {
        editingUser: null,
        editingProduct: null,
        copyProduct:null, // NEW
        updateUser: false,
        portalUser: false,
    },
    setIsPortalActionState: () => { },

    // ------------------------- 
    // DATE PICKER - SETUP NEEDED
    // ------------------------- 
    startDate: new Date(),
    endDate: new Date(),
    setStartDate: () => { },
    setEndDate: () => { },
    shouldFetch: false,
    setShouldFetch: () => { },

    isPortalDataState: {
        allSavedList: null,
        allAdminUsers: null,
        allUsers: null,
        googleAnalyticsData: null,
        customTrackingData: null,
    },
    setIsPortalDataState: () => { },


    //MAIN
    isFilteredGoogleAnalyticsData: {
        dailyUserMetrics: null,
        userDataByLocationMetrics: null,
        pageDataMetrics: null,
        userData: null,
        eventData: null,
        trafficAcquisitionData:null
    },
    setIsFilteredGoogleAnalyticsData: () => { },

    isFilteredCustomDataState: {
        allUsersData: null, //
    },
    setIsFilteredCustomDataState: () => { },

})


export const AdminProvider = ({ children }) => {

    const { setIsRoutingState } = useContext(RoutingContext);
    const { token } = useContext(AuthContext);

    const decodedToken = token ? jwtDecode(token) : null;

    const today = new Date();
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const [startDate, setStartDate] = useState(thirtyDaysAgo);
    const [endDate, setEndDate] = useState(today);
    const [shouldFetch, setShouldFetch] = useState(false)

    // const [isActiveSection, setIsActiveSection] = useState(1);
    const [isActiveSection, setIsActiveSection] = useState(null);

    const initialPortalDataState = {
        allSavedList: null,
        allAdminUsers: null,
        allUsers: null,
        googleAnalyticsData: null,
        customTrackingData: null,
    };
    const [isPortalDataState, setIsPortalDataState] = useState(initialPortalDataState);

    const initialPortalActionState = {
        editingUser: null,
        editingProduct: null,
        copyProduct:null, // NEW
        updateUser: false,
        portalUser: false,
    }
    const [isPortalActionState, setIsPortalActionState] = useState(initialPortalActionState);

    const initialFilteredCustomDataState = {
        allUsersData: null,
    }
    const [isFilteredCustomDataState, setIsFilteredCustomDataState] = useState(initialFilteredCustomDataState);

   
    // const location = useLocation();

    //FETCHING USERS
// useEffect(()=>{
//     if(location.pathname === '/admin'){
//         console.log('run')
//     } else {
//         console.log('hide')
//     }

// },[])
    useEffect(() => {
        if (!token) return;

        if (!decodedToken.role || (decodedToken?.role !== 'admin' && decodedToken?.role !== 'superAdmin')) return;
        const fetchUsers = async () => {
            try {
                setIsRoutingState(prevState => ({
                    ...prevState,
                    isLoading: true,
                }));

                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
             
                const data = await response.json();
           
                if (response.status === 404) {
                    setIsPortalDataState(prevState => ({ ...prevState, allUsers: data.users }));
                }
                if (!response.ok) {
                    throw new Error('Failed to fetch users');
                }

                // const data = await response.json();
                setIsPortalDataState(prevState => ({ ...prevState, allUsers: data.users }));

                if (data?.users) {
                    // Convert the startDate and endDate to dates
                    let start = new Date(startDate);
                    let end = new Date(endDate);

                    // Filter the users array
                    const filteredUsers = data.users.filter(user => {
                        // Convert createdAt to Date and compare
                        let userDate = new Date(user.createdAt);
                        return userDate >= start && userDate <= end;
                    });

                    // Filter the users who were created up to endDate
                    const filteredUsersUpToEndDate = data.users.filter(user => {
                        // Convert createdAt to Date and compare
                        let userDate = new Date(user.createdAt);
                        return userDate <= end;
                    });

                    // Set the filtered data to state (or whatever state you want)
                    setIsFilteredCustomDataState(prevState => ({
                        ...prevState,
                        allUsersData: filteredUsers,
                        allUsersDataToEndDate: filteredUsersUpToEndDate,
                    }));
                }

            } catch (error) {
                // console.error('Error fetching users:', error.message);
            } finally {
                setIsRoutingState(prevState => ({
                    ...prevState,
                    isLoading: false,
                }));
            }
        };

        if (token) {

            fetchUsers();
        }

        // This will ensure that the effect runs once when the component mounts and whenever token, startDate, or endDate changes.
        // }, [token, startDate, endDate, location.pathname === '/admin']);
    }, [
        token,
        startDate,
        endDate,
        isActiveSection
        // isPortalDataState.allUsers
    ]);

    //FETCH ADMIN USERS
    useEffect(() => {

        if (!token) return;
        // Check if the userRole is either admin or superAdmin
        // if (!decodedToken.role || (decodedToken?.role !== 'admin' && decodedToken?.role !== 'superAdmin')) return;
        if (!decodedToken.role || (decodedToken?.role !== 'admin' && decodedToken?.role !== 'superAdmin')) return;
        const fetchUsers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin-users`, {
                    method: 'GET',
                    headers: { 
                        'Authorization': `Bearer ${token}` 
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch users');
                }
                const data = await response.json();

                setIsPortalDataState(prevState => ({
                    ...prevState,
                    allAdminUsers: data.adminUsersList
                }))


            } catch (error) {
                // console.error('Error fetching users:', error.message);
            } finally {
                setIsRoutingState(prevState => ({
                    ...prevState,
                    isLoading: false,
                }));
            }
        }
        if (token) {

            fetchUsers();
        }
    }, [token, isActiveSection]);
    // NEED A BETTER WAY TO CHECK IN ON AUTH



    // ---------------------------------------
    // GOOGLE ANALYTICS
    // ---------------------------------------
    const initialFilteredGoogleAnalyticsData = {
        dailyUserMetrics: null,
        userDataByLocationMetrics: null,
        pageDataMetrics: null,
        userData: null,
        eventData: null,
        trafficAcquisitionData:null

    }
    const [isFilteredGoogleAnalyticsData, setIsFilteredGoogleAnalyticsData] = useState(initialFilteredGoogleAnalyticsData)



    //FETCH GOOGLE ANALYTICS - GOOD
    useEffect(() => {
        
        if (!token) return;
        const formatDateToYYYYMMDD = (date) => {
            let dd = String(date.getDate()).padStart(2, '0');
            let mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
            let yyyy = date.getFullYear();

            return yyyy + mm + dd;
        };

        const fetchAnalytics = async () => {
            // if (!token || !shouldFetch ) return;
            if (!token) return;
            // Check if the userRole is either admin or superAdmin
            if (!decodedToken.role || (decodedToken?.role !== 'admin' && decodedToken?.role !== 'superAdmin')) return;

            try {
                setIsRoutingState(prevState => ({
                    ...prevState,
                    isLoading: true,
                }));

                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/data`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                // const response = await fetch(`http://localhost:3000/data`, {
                //     headers: { 'Authorization': `Bearer ${token}` }
                // });
              

                if (!response.ok) {

                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
          
                setIsPortalDataState(prevState => ({
                    ...prevState,
                    googleAnalyticsData: data,
                }));
             
                if (data?.pageData) {
                    // Convert the startDate and endDate to YYYYMMDD format
                    let formattedStartDate = formatDateToYYYYMMDD(new Date(startDate));
                    let formattedEndDate = formatDateToYYYYMMDD(new Date(endDate));

                    const filteredAnalyticsData = data.pageData?.filter(dataEntry => {
                        return dataEntry.date >= formattedStartDate && dataEntry.date <= formattedEndDate;
                    });
                    const filteredEventAnalyticsData = data.eventData?.filter(dataEntry => {
                        return dataEntry.date >= formattedStartDate && dataEntry.date <= formattedEndDate;
                    });
                    const filteredUserAnalyticsData = data.userData?.filter(dataEntry => {
                        // Compare the formatted dates
                        return dataEntry.dateHourMinute >= formattedStartDate && dataEntry.dateHourMinute <= formattedEndDate;
                    });

                    const filteredTrafficAnalyticsData = data.trafficAcquisitionData?.filter(dataEntry => {
                        return dataEntry.date >= formattedStartDate && dataEntry.date <= formattedEndDate;
                    });
                    // Set the filtered data to state
                    setIsFilteredGoogleAnalyticsData(prevState => ({
                        ...prevState,
                        pageDataMetrics: filteredAnalyticsData,
                        userData: filteredUserAnalyticsData,
                        eventData: filteredEventAnalyticsData,
                        trafficAcquisitionData:filteredTrafficAnalyticsData
                    }));
                }
                if (data?.userData) {
                    // Convert the startDate and endDate to YYYYMMDD format
                    let formattedStartDate = formatDateToYYYYMMDD(new Date(startDate));
                    let formattedEndDate = formatDateToYYYYMMDD(new Date(endDate));

                    // Filter the generalUserMetrics array
                    const filteredAnalyticsData = data?.pageData?.filter(dataEntry => {
                        // Compare the formatted dates
                        return dataEntry.date >= formattedStartDate && dataEntry.date <= formattedEndDate;
                    });

                    // Set the filtered data to state
                    setIsFilteredGoogleAnalyticsData(prevState => ({
                        ...prevState,
                        pageDataMetrics: filteredAnalyticsData
                    }));
                }




            } catch (error) {
                console.error('Error fetching analytics:', error.message);
                // Depending on how your application handles errors, consider:
                // Show an error message to the user
            } finally {
                setIsRoutingState(prevState => ({
                    ...prevState,
                    isLoading: false,
                }));
            }
        };
        if (token) {
            console.log('fetch analytics')
            // fetchSavedLists();
            fetchAnalytics();
        }
        // fetchAnalytics();
        // This will ensure that the effect runs once when the component mounts and whenever token, startDate, or endDate changes.
    }, [
        token,
        // shouldFetch 
        startDate,
        endDate
    ]);



    //FETCH ALL SAVED LISTS - GOOD
    useEffect(() => {
        if (!token) return;
        // Check if the userRole is either admin or superAdmin
        if (!decodedToken.role || (decodedToken?.role !== 'admin' && decodedToken?.role !== 'superAdmin')) return;

        const fetchSavedLists = async () => {
            try {
                setIsRoutingState(prevState => ({
                    ...prevState,
                    isLoading: true,
                }));

                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/all-saved-lists`, {
                    // headers: { 'Authorization': `Bearer ${token}` }
                });

                if (!response.ok) {
                    // console.log({ "failed fetch response": response });
                    throw new Error('Failed to fetch saved lists');
                }

                const data = await response.json();
                setIsPortalDataState(prevState => ({
                    ...prevState,
                    allSavedList: data.allSavedLists,
                }));

                if (data?.allSavedLists) {
                    // Convert the startDate and endDate to dates
                    let start = new Date(startDate);
                    let end = new Date(endDate);

                    // Filter the allSavedLists array
                    const filteredSavedLists = data.allSavedLists.filter(list => {
                        // Convert updatedAt to Date and compare
                        let listDate = new Date(list.updatedAt);
                        return listDate >= start && listDate <= end;
                    });

                    // Set the filtered data to state (or whatever state you want)
                    setIsFilteredCustomDataState(prevState => ({
                        ...prevState,
                        allSavedListData: filteredSavedLists,
                    }));
                }

            } catch (error) {
                console.error('Error fetching saved lists:', error.message);
            } finally {
                setIsRoutingState(prevState => ({
                    ...prevState,
                    isLoading: false,
                }));
            }
        };

        if (token) {
            fetchSavedLists();
        }

        // This will ensure that the effect runs once when the component mounts and whenever token, startDate, or endDate changes.
    }, [token, startDate, endDate]);

//--------------------------------------------------------------
// DEBUG WELCOME TILE
//--------------------------------------------------------------
    useEffect(() => {
        if (!token) return;
        // Check if the userRole is either admin or superAdmin
        if (!decodedToken.role || (decodedToken?.role !== 'admin' && decodedToken?.role !== 'superAdmin')) return;

        if (token) {
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: true,
            }))
            fetch(`${process.env.REACT_APP_BACKEND_URL}/user/${decodedToken.userId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then((response) => response.json())
                .then((data) => {
                    setIsPortalActionState(prevState => ({
                        ...prevState,
                        portalUser: data.user
                    }))
                    setIsRoutingState(prevState => ({
                        ...prevState,
                        isLoading: false,
                    }))

                });
        }

    }, [token, isActiveSection]);



    return (
        <AdminContext.Provider value={{
            isActiveSection,
            setIsActiveSection,
            isPortalDataState,
            setIsPortalDataState,
            isPortalActionState,
            setIsPortalActionState,
            // isFilteredEmailDataState,
            // isFilteredUserDataState,
            // setIsFilteredUserDataState,
            isFilteredGoogleAnalyticsData,
            isFilteredCustomDataState,
            setIsFilteredCustomDataState,

            startDate,
            endDate,
            setStartDate,
            setEndDate,
        }
        }>
            {children}
        </AdminContext.Provider>
    );

};



import styles from './Portal.module.css';
import { useState, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { AdminContext } from '../../../Context/Admin.Context';
import { AuthContext } from '../../../Context/Auth.Context';


import {jwtDecode} from "jwt-decode"

import SideBar from '../../PortalComponents/PortalUi/PortalSidebar/Sidebar';
import TheTable from '../../PortalComponents/TableElements/TheTable';
import PortalBody from '../../PortalComponents/PortalUi/PortalBody/PortalBody';



const PortalHomePage = () => {
    const { isActiveSection, setIsActiveSection } = useContext(AdminContext);


    // --------------------------
    //---AUTH CONTEXT
    // --------------------------

    const { token } = useContext(AuthContext);
    const decodedToken = token ? jwtDecode(token) : null;


    useEffect(() => {
        setIsActiveSection(1)
    }, []);



    return (

        <div className={styles.pageWrapper}>
            <div className={styles.portalContainer}>
                <SideBar />
                <PortalBody>
                    <TheTable />
                </PortalBody>
            </div>
        </div>
    )
}

export default PortalHomePage;
import { useState, useContext } from "react";
import { useNavigate } from "react-router";
import {jwtDecode} from "jwt-decode";

import { useForm } from "../../../Hooks/FormHook/form-hook";

import ConfirmationModal from "../../../Shared/Components/Modals/ConfirmationModal/ConfirmationModal";
import CustomModal from "../../../Shared/Components/Modals/CustomModal/CustomModal";
import { VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE, VALIDATOR_EMAIL } from "../../../Utils/validators";
import { AdminContext } from "../../../Context/Admin.Context";
import { AuthContext } from "../../../Context/Auth.Context";
import { RoutingContext } from "../../../Context/Routing.Context";

import PortalForm from "../../PortalComponents/FormElements/PortalForm";
// import Button from "../../../Shared/Components/Buttons/Buttons";
import SelectInput from "../../../Shared/Components/UI/FormElements/Select/Select";
import Input from "../../../Shared/Components/UI/FormElements/Input/Input";
import { faEye, faEyeSlash, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import PortalFormRow from "../../PortalComponents/FormElements/PortalFormRow";

import PortalFormSectionElementsBox from "../../PortalComponents/FormElements/PortalFormSectionElementsBox";
import TheButton from "../../../Shared/Components/UI/TheButton/TheButton";
import Modal from "../../../Shared/Components/UI/TheModals/TheModal";

const CreateAdminPortalFormPage = () => {
    const redirect = useNavigate();
    const { setIsActiveSection } = useContext(AdminContext);
    const { token } = useContext(AuthContext);
    const { setIsRoutingState } = useContext(RoutingContext);

    const decodedToken = token ? jwtDecode(token) : null;

    const [error, setError] = useState();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showCustomModal, setShowCustomModal] = useState(false);

    const [accountRole, setAccountRole] = useState('');

    const [passwordInputType, setPasswordInputType] = useState("password");
    // const [confirmPasswordInputType, setConfirmPasswordInputType] = useState("password");
    const togglePasswordVisibility = () => {
        setPasswordInputType((prevState) =>
            prevState === "password" ? "text" : "password"
        );
    };

    const [formState, inputHandler] = useForm({
        // username: {
        //     value: '',
        //     isValid: true
        // },
        email: {
            value: '',
            isValid: false
        },
        firstName: {
            value: '',
            isValid: false
        },
        lastName: {
            value: '',
            isValid: false
        },
        // role: {
        //     value: '',
        //     isValid: false
        // },
        password: {
            value: '',
            isValid: false
        },
    },
        false
    );

    const defaultModal = {
        show: false,
        title: '',
        message: '',
        errorList: [],
        actionText: 'Close',
        wasSuccessful: false,
        onConfirm: null,
        onCancel: null,
        onClose: null,
        cancelText: '',
        confirmText: '',
    }
    const [modalInfo, setModalInfo] = useState(defaultModal);

    const handleAccountRole = (event) => {
        setAccountRole(event.target.value);
        
    };

    const closeModal = () => {
        setShowCustomModal(false);
    };

    const newUserSuccessHanlder = () =>{
        setModalInfo({ ...modalInfo, show: false });
        setIsActiveSection(1);
        redirect('/admin')   
    }
    const handleSubmit = async (event) => {
        // console.log(formState)
        event.preventDefault();
        setIsRoutingState(prevState => ({
            ...prevState,
            isLoading: true,
        }))
        // setIsLoading(true)

        let errorMessage = [];
        if (!formState.inputs.firstName.value) errorMessage.push('First name missing or invalid.');
        if (!formState.inputs.lastName.value) errorMessage.push('Last name missing or invalid.');
        if (!formState.inputs.email.value) errorMessage.push('Email missing or invalid.');
        if (!accountRole) errorMessage.push('Role missing or invalid.');
        // if (!formState.inputs.role.value) errorMessage.push('Role missing or invalid.');
        if (!formState.inputs.password.value) errorMessage.push('Password missing or invalid.');

        if (!formState.isValid) {
            // console.log(formState)
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false,
            }))
            setModalInfo({
                show: true,
                title: 'Admin creation failed',
                message: `Please fix validation error(s) below`,
                errorList: errorMessage,
                confirmText: 'Try again',
                onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });
            // setShowCustomModal(true);
            // setError({
            //     title: 'Admin Creation Failed',
            //     message: `Form is not valid.`,
            //     errorList: errorMessage
            // });

            return;
        }
        if (errorMessage.length > 0) {
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false,
            }))
            setModalInfo({
                show: true,
                title: 'Change request failed',
                message: `Please fix the error(s) below`,
                errorList: errorMessage,
                confirmText: 'Try again',
                onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });
            // setShowCustomModal(true);
            // setError({
            //     title: 'Validation error',
            //     message: `Please fix the following errors:`,
            //     errorList: errorMessage
            // });

            return;
        }

        try {
            setIsRoutingState(prevState=>({...prevState, isLoading:true}))
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/add-admin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    email: formState.inputs.email.value,
                    // username: formState.inputs.username.value,
                    firstName: formState.inputs.firstName.value,
                    lastName: formState.inputs.lastName.value,
                    password: formState.inputs.password.value,
                    // role: formState.inputs.role.value,
                    role: accountRole,
                    creator: decodedToken.userId,
                    //   role:accountRole,

                })
            });

            if (response.status === 201) {
                setModalInfo({
                    show: true,
                    title: 'User created',
                    message: 'The admin user was created successfully!',
                    errorList: [],
                    confirmText: 'Close',
                    wasSuccessful: true,
                    onConfirm: newUserSuccessHanlder,
                    onClose: newUserSuccessHanlder
                });
                // setAppUsers(prevUsers => [
                //     ...prevUsers,
                //     response.user
                // ]);
                // setUpdateTheUser(prevState => !prevState);
               
                // setIsRoutingState(prevState => ({
                //     ...prevState,
                //     isLoading: false,
                // }))
             
            } else if (response.status === 422) {
                setModalInfo({
                    show: true,
                    title: 'Account creation failed',
                    message: `That email is not available`,
                    errorList: [],
                    confirmText: 'Try again',
                    onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                    onClose: () => setModalInfo({ ...modalInfo, show: false }),
                });
                // setIsRoutingState(prevState => ({
                //     ...prevState,
                //     isLoading: false,
                // }))
                // setShowCustomModal(true);
                // setError({
                //     title: "Account creation failed",
                //     message: "That email is not available",
                //     errorList: response.message,
                // });
                // console.log('response', response)
            } else {
                setModalInfo({
                    show: true,
                    title: 'Something went wrong',
                    message: 'The user could not be created. Please contact an administrator for assistance.',
                    errorList: [],
                    confirmText: 'Try again',
                    onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                    onClose: () => setModalInfo({ ...modalInfo, show: false }),
                });
            }

            // if (!response.ok) {

            //     const errorData = await response.json();
            //     // console.log(errorData)
            //     throw new Error(errorData);
            // }
            // if (response.ok) {
            //     setAppUsers(prevUsers => [
            //         ...prevUsers,
            //         response.user
            //     ]);
            //     setActiveSection(1);
            //     console.log('response', response)
            // }
        } catch (error) {
            setModalInfo({
                show: true,
                title: 'Something went wrong',
                message: 'The user could not be deleted. Please contact an administrator for assistance.',
                errorList: error.message, //TEMP - should be []
                confirmText: 'Try again',
                onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });
            // setIsRoutingState(prevState => ({
            //     ...prevState,
            //     isLoading: false,
            // }))
            // setShowCustomModal(true);
            // setError({
            //     title: "Account creation failed!",
            //     message: error.message,
            //     errorList: null
            // });
            // console.log('response', error)

        } finally {
            setIsRoutingState(prevState=>({...prevState, isLoading:false}))
        }
    }
    return (
        <>
          <Modal
                icon
                iconName="close"
                styleName="baseIconButton"
                iconOnClick={modalInfo.onClose}
                show={modalInfo.show}
                title={modalInfo.title}
                message={modalInfo.message}
                errorList={modalInfo.errorList}
                closeOnBackdropClick={false}
                actionText={modalInfo.actionText}
                actionBox={
                    <>
                        {modalInfo.onConfirm && (
                            <span onClick={modalInfo.onConfirm}>
                                <TheButton styleName="red" animated="true">
                                    {modalInfo.confirmText}
                                </TheButton>
                            </span>
                        )}
                        {modalInfo.onCancel && (
                            <span onClick={modalInfo.onCancel}>
                                <TheButton styleName="white" animated="true">
                                    {modalInfo.cancelText}
                                </TheButton>
                            </span>
                        )}
                    </>
                }
            />
            <PortalForm
                title="Create Administrator Account"
                subtitle="Delegate Control"
                headline="Create administrative accounts with admin or super admin access."
                onSubmit={handleSubmit}
            >
                <PortalFormSectionElementsBox>
                    <PortalFormRow>
                        <SelectInput
                            id="role"
                            name="role"
                            value={accountRole}
                            requireAsterix
                            validators={[VALIDATOR_REQUIRE()]}
                            // errorText="Category required."
                            labelName="Account Role"
                            onChange={handleAccountRole}
                            options={[
                                { value: "", label: "choose role", disabled: true },
                                { value: "admin", label: "Administrator" },
                                { value: "superAdmin", label: "Super Administrator" },

                            ]}
                        />
                    


                    </PortalFormRow>
                    <PortalFormRow>
                        <Input
                            id="firstName"
                            type="text"
                            element="input"
                            label="First name"
                            validators={[VALIDATOR_MINLENGTH(2)]}
                            errorText="Minimum 2 characters required."
                            onInput={inputHandler}
                            requireAsterix
                        />
                    </PortalFormRow>
                    <PortalFormRow>
                        <Input
                            id="lastName"
                            type="text"
                            element="input"
                            label="Last name"
                            validators={[VALIDATOR_MINLENGTH(2)]}
                            errorText="Minimum 2 characters required."
                            onInput={inputHandler}
                            requireAsterix
                        />
                    </PortalFormRow>
                    <PortalFormRow>
                        <Input
                            id="email"
                            type="text"
                            element="input"
                            label="Email"
                            validators={[VALIDATOR_EMAIL()]}
                            errorText="Please enter a valid email address."
                            onInput={inputHandler}
                            icon={faEnvelope}
                            requireAsterix
                        />
                    </PortalFormRow>
                    <PortalFormRow>
                        <Input
                            requireAsterix
                            // labelColor="#4A4946"
                            id="password"
                            element="input"
                            // type="password"
                            type={passwordInputType}
                            label="Password"
                            validators={[VALIDATOR_MINLENGTH(8)]}
                            errorText="Minimum of 8 characters."
                            onInput={inputHandler}
                            icon={passwordInputType === "password" ? faEyeSlash : faEye}

                            onIconClick={togglePasswordVisibility}
                        />
                    </PortalFormRow>
                </PortalFormSectionElementsBox>
                <div>
                    <TheButton styleName="red" animated="true">
                        Create Account
                    </TheButton>
                    {/* <Button styleName="black" type="submit" ></Button> */}
                </div>
            </PortalForm>
            {showCustomModal && (
                <CustomModal
                    show={showCustomModal}
                    title={error.title}
                    message={error.message}
                    errorList={error.errorList}
                    closeHandler={closeModal}
                />

            )}
            {accountRole === "superAdmin" && !error
                ?
                (
                    <ConfirmationModal
                        show={showConfirmationModal}
                        title="Confirmation Required!"
                        message="This action will create a Super Administrator Account.This user will have full access to create, edit or delete all products. This user can create, edit or delete all users, including administrator accounts.  and any or all users, including administrator and super administrator accounts. Are you sure you would like to proceed?"
                        confirmText="Confirm"
                        cancelText="Cancel"
                        onConfirm={() => {
                            setShowConfirmationModal(false);
                        }}
                        onCancel={() => {
                            setShowConfirmationModal(false);
                        }}
                    />
                )
                :
                (
                    <ConfirmationModal
                        show={showConfirmationModal}
                        title="Confirmation Required!"
                        message="This action will create an Administrator Account. This user will have full access to create, edit or delete all products. This user can approve any user. Are you sure you would like to proceed?"
                        confirmText="Confirm"
                        cancelText="Cancel"
                        onConfirm={
                            () => {
                                alert('admin profile created')
                            }
                        }
                        onCancel={() => {
                            setShowConfirmationModal(false);
                        }}

                    />
                )
            }
        </>
    )
}

export default CreateAdminPortalFormPage;
import styles from "./PageWrapperTemplate.module.css"
const PageWrapperTemplate = ({ marginTop,children }) => {
    // return<div className={styles.pageWrapperTemplateContainer}>{children}</div>
    return (
        <>

            <div 
               style={{
              
                marginTop: marginTop ? marginTop: undefined
            }}
            className={styles.pageWrapperTemplateContainer}>
                <div className={styles.gutter1} />
                <div className={styles.pageWrapperContent}>
                    {children}
                </div>
                <div className={styles.gutter1} />
            </div>

        </>
    )
}

export default PageWrapperTemplate;

import { format } from 'date-fns';
import {jwtDecode} from "jwt-decode";
import { useContext, useState, useMemo } from "react";
import Modal from "../../../Shared/Components/UI/TheModals/TheModal";
import TheButton from '../../../Shared/Components/UI/TheButton/TheButton';
import { useNavigate } from 'react-router';
import { AdminContext } from "../../../Context/Admin.Context";
import { AuthContext } from "../../../Context/Auth.Context";
import { RoutingContext } from '../../../Context/Routing.Context';

import Icon from "../../../Shared/Components/IconElements/Icon";
import TheTable from "../../PortalComponents/TableElements/TheTable"
import TheTableHeader from "../../PortalComponents/TableElements/TheTableHeader"
import TheTablePagination from "../../PortalComponents/TableElements/TheTablePagination"
import TheTableActionBox from "../../PortalComponents/TableElements/TheTableActionBox";
// import TheTableSortingHeader from "../../PortalComponents/TableElements/TheTableSortingHeader";
// import ConfirmationModal from "../../../Shared/Components/Modals/ConfirmationModal/ConfirmationModal";
import { useHttpClient } from "../../../Hooks/HttpHook/http-hook";
import TheIcon from '../../../Shared/Components/UI/TheIcon/TheIcon';
import TheTableWrapper from '../../PortalComponents/TableElements/TheTableWrapper';

const AllUsersTablePage = () => {
 const { setIsRoutingState } = useContext(RoutingContext)
 
    const { token } = useContext(AuthContext);
    const redirect = useNavigate();
   
    const decodedToken = token ? jwtDecode(token) : null;

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedUserForDeletion, setSelectedUserForDeletion] = useState(null)

    const {
        setIsActiveSection,
        setIsPortalActionState,
        // setUpdateTheUser, 
        isPortalDataState,
        isFilteredCustomDataState
    } = useContext(AdminContext);

    const {sendRequest} = useHttpClient();

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    

    // Function to handle sort change
    //  const handleSortChange = () => {  // NEW
    //     setSortDescending(prevState => !prevState);
    // }

    // function getSpecificInteractionCount(interactions, interactionType) {
    //     return interactions?.[interactionType]?.count || 0;
    // }

    // function getInteractionCountByPrefix(interactions, keyPrefix) {
    //     interactions = interactions ?? {};
    //     // Extract all interaction keys that start with the given prefix
    //     const matchingKeys = Object.keys(interactions).filter(key => key.startsWith(keyPrefix));

    //     // Sum up the counts for these keys
    //     const total = matchingKeys.reduce((acc, key) => acc + interactions[key].count, 0);

    //     return total;
    // }

    const defaultModal = {
        show: false,
        title: '',
        message: '',
        errorList: [],
        actionText: 'Close',
        wasSuccessful: false,
        onConfirm: null,
        onCancel: null,
        onClose: null,
        cancelText: '',
        confirmText: '',
    }
    const [modalInfo, setModalInfo] = useState(defaultModal);
    const hasData = Array.isArray(isPortalDataState.allUsers) && isPortalDataState.allUsers.length > 0;
    if(!hasData){
return <h1>No Users</h1>
    }
  
    const columns = [

        {
            key: 'store',
            title: 'Retailer',
            render: row => row?.store ? row.store : 'Not Provided'
        },
        {
            key: 'status', title: 'Status',
            render: row => row.status === "approved" ? "Approved"
                : row.status === "notApproved" ? "Not Approved"
                    : row.status === "pending" ? "Pending" : row.status
        },
        {
            key: 'role',
            title: 'Role',
            render: row =>
                row.role === "superAdmin"
                    ? "Super Admin"
                    : row.role === "admin"
                        ? "Admin"
                        : row.role
        },
        // { key: 'role', title: 'Role' },
        // { key: 'username', title: 'Username' },

        { key: 'firstName', title: 'Name' },
        { key: 'email', title: 'Email' },
        // {
        //     key: 'visitCount',
        //     title: 'Visit Count',
        //     render: row => row.sessionData ? row.sessionData.visitCount : '0'
        // },
        // {
        //     key: 'click_print',
        //     title: 'Click Print Count',
        //     render: row => {
        //         // Find the corresponding tracking data for the user
        //         const userTracking = isPortalDataState?.customTrackingData.find(track => track.userId === row._id);

        //         // If found, return the click_print count, otherwise return 0
        //         return getSpecificInteractionCount(userTracking?.trackingData?.interactions, 'click_print');
        //     }
        // },
        // {
        //     key: 'see_details',
        //     title: 'See Details Count',
        //     render: row => {
        //         // Find the corresponding tracking data for the user
        //         const userTracking = isPortalDataState?.customTrackingData.find(track => track.userId === row._id);

        //         // If found, return the count for interactions starting with 'see_details_', otherwise return 0
        //         return getInteractionCountByPrefix(userTracking?.trackingData?.interactions, 'see_details_');
        //     }
        // },
        // {
        //     key: 'interactionCount',
        //     title: 'Interaction Count',
        //     render: row => {
        //         if (row.sessionData && row.sessionData.pages) {
        //             return Object.values(row.sessionData.pages).reduce((acc, curr) => {
        //                 if (curr && curr.interactions) {
        //                     return acc + Object.values(curr.interactions).reduce((a, b) => a + b, 0);
        //                 }
        //                 return acc;
        //             }, 0)
        //         } else {
        //             return 'N/A'
        //         }
        //     }
        // // },
        // {
        //     key: 'interactionCount',
        //     title: 'Interaction Count',
        //     render: row => {
        //         // Find the corresponding tracking data for the user
        //         const userTracking = isPortalDataState?.customTrackingData.find(track => track.userId === row._id);
        //         // If found and has trackingData, return the interactionCount, otherwise return 'N/A'
        //         return userTracking?.trackingData?.interactionCount || 'N/A';
        //     }
        // },
        {
            key: 'savedLists', title: 'Saved List',
            render: row => row.savedLists
        },
        { key: 'createdAt', title: 'Joined', render: row => format(new Date(row.createdAt), 'dd/MM/yyyy') },
        {
            key: 'actions',
            title: 'Actions',
            render: row => (
                <TheTableActionBox>
                    {/* <div className={styles.tableActionsBox}> */}
                    <span
                        onClick={
                            () => {
                                setIsActiveSection(9)
                                // handleSectionClick(9)
                                setIsPortalActionState(prevState => ({
                                    ...prevState,
                                    editingUser: row._id,
                                }))
                                // setIsEditingUser(row._id)
                                // console.log(row._id)
                            }
                        }
                    >
                        <Icon name="accountLocked" />
                    </span>
                    <span
                        onClick={
                            () => {
                                setIsActiveSection(7)
                                setIsPortalActionState(prevState => ({
                                    ...prevState,
                                    editingUser: row._id,
                                }))
                                // handleSectionClick(7)
                                // setIsEditingUser(row._id)
                                // console.log(row._id)
                            }
                        }
                    >
                        <Icon name="admin" />
                    </span>

                    <span
                        onClick={
                            () => {
                                setSelectedUserForDeletion(row?._id);
                                // console.log(row._id)
                                // setShowConfirmationModal(true);
                                setModalInfo({
                                    show: true,
                                    title: 'Are you sure you want to proceed?',
                                    message: `You are about to delete a registered user account. This action cannot be undone. Please confirm to continue.`,

                                    // onConfirm: handleDeleteClick,
                                    onConfirm: handleDeleteClick,
                                    cancelText: "Go back",
                                    confirmText: "Confirm",
                                    onCancel: () => setModalInfo({ ...modalInfo, show: false }),
                                    onClose: () => setModalInfo({ ...modalInfo, show: false }),
                                });
                            }
                        }
                    >
                        <TheIcon name="delete" />

                    </span>

                    {/* </div> */}
                </TheTableActionBox>
            )

        },
    ];

    const userDeleteSuccessHanlder = () => {
        setModalInfo({ ...modalInfo, show: false });
        setIsActiveSection(1);
        redirect('/admin')
    }


    const handleDeleteClick = async () => {
        // console.log(userId)
        setIsRoutingState(prevState => ({ ...prevState, isLoading: true }))
        try {
            const response = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/remove-user-account/${selectedUserForDeletion}`,
                'DELETE',
                JSON.stringify({
                    adminId: decodedToken.userId,
                }),
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }

            );
            if (response.message === "user deleted") {
                setModalInfo({
                    show: true,
                    title: 'User deleted',
                    message: 'The user account was deleted successfully!',
                    errorList: [],
                    confirmText: 'Close',
                    wasSuccessful: true,
                    onConfirm: userDeleteSuccessHanlder,
                    onClose: userDeleteSuccessHanlder
                });

            } else if (!response.message === "user deleted") {
                setModalInfo({
                    show: true,
                    title: 'Something went wrong',
                    message: 'The user could not be deleted. Please contact an administrator for assistance.',
                    errorList: [],
                    confirmText: 'Try again',
                    onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                    onClose: () => setModalInfo({ ...modalInfo, show: false }),
                });
            }
            // setUpdateTheUser(prevState => !prevState);

        } catch (err) {
            setModalInfo({
                show: true,
                title: 'Something went wrong',
                message: 'The user could not be deleted. Please contact an administrator for assistance.',
                errorList: [],
                confirmText: 'Try again',
                onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });
        } finally {
            setIsRoutingState(prevState => ({ ...prevState, isLoading: false }))
        }

    };

  
    return (
        <>
            <Modal
                icon="true"
                iconName="close"
                styleName="lightIconButton"
                iconOnClick={modalInfo.onClose}
                show={modalInfo.show}
                title={modalInfo.title}
                message={modalInfo.message}
                errorList={modalInfo.errorList}
                closeOnBackdropClick={false}
                actionText={modalInfo.actionText}
                actionBox={
                    <>
                        {modalInfo.onConfirm && (
                            <span onClick={modalInfo.onConfirm}>
                                <TheButton styleName="red" animated='true'>
                                    {modalInfo.confirmText}
                                </TheButton>
                            </span>
                        )}
                        {modalInfo.onCancel && (
                            <span onClick={modalInfo.onCancel}>
                                <TheButton styleName="white" animated='true'>
                                    {modalInfo.cancelText}
                                </TheButton>
                            </span>
                        )}
                    </>
                }
            />
            {/* <Modal
                title="Modal Header"
                show={showConfirmationModal}
                message="This action will delete the user. Please confirm."

                onConfirm={() => {
                    handleDeleteClick(selectedUserForDeletion);
                    setShowConfirmationModal(false);
                    setSelectedUserForDeletion(null);
                }}
                onCancel={() => {
                    setShowConfirmationModal(false);
                    setSelectedUserForDeletion(null);
                }}
            ></Modal> */}

            <TheTableWrapper>
                <TheTableHeader
                    title="Users"
                    subtitle="Admin Portal"
                    headline="All non administrative users"
                />
                {!hasData ? <h1>No Data</h1> :
                    <>
                        <TheTable columns={columns} data={isPortalDataState?.allUsers?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)} />
                        <TheTablePagination
                            itemsPerPage={itemsPerPage}
                            tableData={isPortalDataState?.allUsers}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}

                        />
                    </>
                }
            </TheTableWrapper>

        </>
    )
}

export default AllUsersTablePage;
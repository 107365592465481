
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { LanguageContext } from "../../../../Context/Language.Context";
import { SFWProductsContext } from "../../../../Context/CategoryContext";

import { RetailerContext } from "../../../../Context/Retailer.Context";

import styles from './Category.module.css'
import rangeImage from '../../../Theme/Product/SubcategoryImages/cooking-1.webp';
import dishImage from '../../../Theme/Product/SubcategoryImages/dish-4.webp';
import laundryImage from '../../../Theme/Product/SubcategoryImages/laundry-16.webp';
import lgStudioImage from '../../../Theme/Product/SubcategoryImages/lgstudio-kitchen-3.webp';
import fridgeImage from '../../../Theme/Product/SubcategoryImages/fridge-12.webp';
import ScrollableRow from "../../../Components/ProductElements/Containers/ScrollableRow/ScrollableRow";



import HeroContainer from "../../../Components/UI/HeroContainer/HeroContainer";
import SectionTextContainer from "../../../Components/UI/SectionTextContainer/SectionTextContainer";
import PageWrapperTemplate from "../../../Components/UI/Templates/PageWrapperTemplate";
import BodyContainer from "../../../Components/UI/BodyContainer/BodyContainer";
import TheButton from "../../../Components/UI/TheButton/TheButton";
import ScrollableBodyContainer from "../../../Components/UI/BodyContainer/ScrollableBodyContainer";
import SectionContainer from "../../../Components/UI/SectionContainer/SectionContainer";
import { ExternalLinkButton } from "../../../../Utils/link-helper";

const categoryDescriptionEN = {
  "Cooking": `The star of the show, LG kitchen ranges & ovens offer precision and performance unlike any others in the market. With a variety of innovative features, our ranges can help you create dishes that will leave your guests demanding an encore.`,
  "Dishwasher": `LG’s sleek and powerful dishwashers will make your dishes shine. With intuitive controls, adjustable racks, and a large load capacity LG dishwashers make kitchen clean up a breeze.`,
  "LG Studio": `Say hello to the LG Studio Kitchen. With innovative freestanding and built-in appliances that perform flawlessly and look awesome, you may never want to leave your home again.`,
  "Laundry": `Whether you’re looking for side-by-side laundry machines, stackable washers and dryers, or all-in-one laundry appliances that offer powerful washing and drying in a single, space-saving machine, LG offers a wide range of reliable, innovative solutions.`,
  "Refrigeration": `Make the most out of life and look to LG for stylish and innovative appliances that fit your lifestyle and match your home’s décor.`,
};
const categoryDescriptionFR = {
  "Cooking": `Les cuisinières et les fours de LG sont les grandes vedettes et offrent une précision et une performance inégalées sur le marché. Grâce à une variété de fonctionnalités innovantes, nos cuisinières vous aideront à créer des plats si délicieux que vos invités en redemanderont.`,
  "Dishwasher": `Élégants et puissants, les lave-vaisselle de LG feront briller votre vaisselle. Avec des commandes intuitives, des paniers ajustables et une grande capacité, les lave-vaisselle de LG peuvent désencombrer votre cuisine en un tour de main.`,
  "LG Studio": `Dites bonjour à la cuisine LG Studio. Grâce à ces appareils innovateurs non encastrés et intégrés qui ont fière allure et fonctionnent parfaitement, vous ne voudrez plus jamais sortir de votre maison.`,
  "Laundry": `Que vous recherchiez des appareils de lavage côte à côte, des laveuses et des sécheuses empilables, ou des appareils tout-en-un qui offrent un lavage et un séchage puissants dans une seule unité compacte, LG offre une vaste gamme de solutions fiables et innovantes.`,
  "Refrigeration": `Profitez pleinement de la vie et tournez-vous vers LG pour des appareils élégants et innovants qui correspond à votre style de vie et votre décor.`,
};
const categoryBackgroundImages = {
  "Cuisson": rangeImage,
  "Cooking": rangeImage,
  "Dishwasher": dishImage,
  "Lave-vaisselle": dishImage,
  "LG Studio": lgStudioImage,
  // "LG Studio": lgStudioImage,
  "Laundry": laundryImage,
  "Lessive": laundryImage,
  "Refrigeration": fridgeImage,
  "Réfrigérateur": fridgeImage,
};
const categoryNamesInEnglish = {
  "Cuisson": "Cooking",
  "Lave-vaisselle": "Dishwasher",
  "Lessive": "Laundry",
  "Réfrigérateur": "Refrigeration",
  "LG Studio": "LG Studio"
};
const categoryBackgroundPositions = {
  "Cooking": 'center',
  "Dishwasher": 'bottom',
  "LG Studio": 'center',
  "Laundry": 'center',
  "Refrigeration": 'top',
};
const getBackgroundPosition = (category) => {
  return categoryBackgroundPositions[category] || 'center';
}
const getEnglishCategoryName = (category) => {
  return categoryNamesInEnglish[category] || category;
};


const getCategoryDescription = (category, lang) => {
  const englishCategory = getEnglishCategoryName(category);
  return lang === "EN"
    ? categoryDescriptionEN[englishCategory]
    : categoryDescriptionFR[englishCategory];
};

//DISPLAY SUBCAT BY ORDER 1/3

const subCategoryOrderEN = {
  "LG Studio": [
    "Laundry",
    "Refrigeration",
    "Built-in Cooking",
    "Free-standing Cooking",
    "Ventilation",
    "Dishwashers",
  ],
  "Laundry": [
    "Front Load",
    "WashTower",
    "Top Load",
    "Specialty Laundry",
    // NEW
    "Accessories",

  ],
  "Refrigeration": [
    "36\" French Door Standard Depth",
    "36\" French Door Counter Depth",
    "33\" French Door Standard Depth",
    "33\" French Door Counter Depth",
    "30\" French Door",
    "Top and Bottom Mount",
    "Side-by-Side",
    "Column",
    "Kimchi",
  ],
  "Cooking": [
    "Electric Slide-in Ranges",
    "Electric Free-standing Ranges",
    "Induction Ranges",
    "Gas Ranges",
    "Wall Ovens",
    "Cooktops",
    "Over-the-Range Microwaves",
    "Countertop Microwaves",
  ],

  "Dishwasher": [
    "Built-in"
  ],
}
const subCategoryOrderFR = {
  "LG Studio": [
    "Lessive",
    "Réfrigérateur",
    "Cuisson intégré",
    "Cuisson autonome",
    "Ventilation",
    "Lave-vaisselles",
  ],
  "Laundry": [
    "Chargement frontal",
    "WashTower",
    "Chargement vertical",
    "Appareils spécialisés",
    // NEW
    "Accessoires"
  ],
  "Refrigeration": [
    "Porte à deux battants profondeur standard de 36 po",
    "Porte à deux battants profondeur de comptoir de 36 po",
    "Porte à deux battants profondeur standard de 33 po",
    "Porte à deux battants profondeur de comptoir de 33 po",
    "Porte à deux battants de 30 po",
    "Congélateur en haut et bas",
    "Côte-à-côte",
    "Colonne",
    "Kimchi"
  ],
  "Cooking": [
    "Cuisinière encastrable électrique",
    "Cuisinières encastrable électriques",
    "Cuisinières à inductions",
    "Cuisinière au gaz",
    "Four mural",
    "Surface de cuisson",
    "Four à micro-ondes à hotte intégrée",
    "Four à micro-ondes de comptoir",
  ],
  "Dishwasher": [
    "Intégré"
  ],
}

const CategoryPage = () => {

  const { category } = useParams();

  const { isHomeDepotApp } = useContext(RetailerContext)
  const { filteredProductsByCategory } = useContext(SFWProductsContext);
  const { language, translatedText } = useContext(LanguageContext);

  const products = filteredProductsByCategory[getEnglishCategoryName(category)];

  // const backgroundImage = categoryBackgroundImages[category];
  const backgroundImage = categoryBackgroundImages[getEnglishCategoryName(category)];

  const translateCategoryName = (category) => {
    if (language === "FR") {
      return Object.keys(categoryNamesInEnglish).find(
        (key) => categoryNamesInEnglish[key] === category
      ) || category;
    } else {
      return category;
    }
  };


  // Helper function to get subcategory based on language
  const getSubcategory = (product, language) => {
    return language === 'EN' ? product.subcategoryEN : product.subcategoryFR;
  }

  // Use the helper function in your code
  const subcategories = products
    ? Array.from(new Set(products.map((product) => getSubcategory(product, language))))
    : [];

  //DISPLAY SUBCAT BY ORDER 2/3

  const subcatLangMap = language === "FR" ? subCategoryOrderFR : subCategoryOrderEN;
  const desiredOrder = subcatLangMap[category] || [];
  const orderedSubcategories = desiredOrder.filter(subcat => subcategories.includes(subcat));


  //DISPLAY SUBCAT BY ORDER 3/3  
  const subcategoryProducts = orderedSubcategories.map((subcategory) => {
    return {
      subcategory,
      products: products.filter(
        (product) => getSubcategory(product, language) === subcategory
      ),
    };
  });


  // STEP UP CHARTS
  const stepUpChartLinkMapLG = {
    Cooking: {
      EN: "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Step-Up+Charts/English/LG+Cooking+Step+Up+Chart+En.pdf",
      FR: "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Step-Up+Charts/French/LG+Cooking+Step+Up+Chart+Fr.pdf",
    },
    Laundry: {
      EN: "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Step-Up+Charts/English/LG+Laundry+Step+Up+Chart.pdf",
      FR: "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Step-Up+Charts/French/LG+Laundry+Step+Up+Chart+Fr.pdf",
    },
    Dishwasher: {
      EN: "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Step-Up+Charts/English/LG+Dish+Step+Up+Chart+En.pdf",
      FR: "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Step-Up+Charts/French/LG+Dish+Step+Up+Chart+Fr.pdf",
    },
    Refrigeration: {
      EN: "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Step-Up+Charts/English/LG+Fridge+Step+Up+Chart+En.pdf",
      FR: "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Step-Up+Charts/French/LG+Fridge+Step+Up+Chart+Fr.pdf",
    }
  };

  const stepUpChartLinkMapHD = {
    Cooking: {
      EN: "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Step+Up+Charts/English/LG+Cooking+Step+Up+Chart+HD+En.pdf",
      FR: "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Step+Up+Charts/French/LG+Cooking+Step+Up+Chart+HD+Fr.pdf",
    },
    Laundry: {
      EN: "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Step+Up+Charts/English/LG+HD+Laundry+Step+Up+Chart+En.pdf",
      FR: "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Step+Up+Charts/French/LG+HD+Laundry+Step+Up+Chart+Fr.pdf",
    },
    Dishwasher: {
      EN: "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Step+Up+Charts/English/LG+HD+Dish+Step+Up+Chart+En.pdf",
      FR: "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Step+Up+Charts/French/LG+HD+Dish+Step+Up+Chart+Fr.pdf",
    },
    Refrigeration: {
      EN: "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Step+Up+Charts/English/LG+HD+Fridge+Step+Up+En.pdf",
      FR: "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Step+Up+Charts/French/LG+HD+Fridge+Step+Up+Chart+Fr.pdf",
    }
  };

  const renderRetailerStepUpChartLinks = (category) => {
    const linkMap = isHomeDepotApp.isHomeDepotActive ? stepUpChartLinkMapHD : stepUpChartLinkMapLG;
    const categoryLinkData = linkMap[category];

    if (!categoryLinkData) return null; // Handle missing category
    return categoryLinkData[language]; // Return the correct language-specific link
  };

  return (
    <>
      {/* <PageWrapperTemplate> */}
      <div className={styles.templateWrapper}>
        <BodyContainer>
          <HeroContainer
            backgroundImage={backgroundImage}
            backgroundPosition={getBackgroundPosition(getEnglishCategoryName(category))}
            title={translateCategoryName(category)}

          />
          <SectionContainer>
            <SectionTextContainer
              buttonElement={
                <div className={styles.buttonBox2}>
                  <ExternalLinkButton href={renderRetailerStepUpChartLinks(category)} linkText={`${translatedText.viewStepUpChart} `} />
                  {/* <TheButton
                    iconName="forward"
                    buttonIcon="true"
                   styleName="blackIconButton2"
                    // styleName="black"
                    animated="true"
                    link={`${isHomeDepotApp.isHomeDepotPrefix}/compare/${getEnglishCategoryName(category)}`}
                    linkText={`${translatedText.viewStepUpChart} `}
                  /> */}
                </div>

              }
              studioExclusion={category}
              titleText={`${translatedText.explore} ${translateCategoryName(category)}`}
              descriptionText={getCategoryDescription(category, language)}
            >
            </SectionTextContainer>
          </SectionContainer>
        </BodyContainer>

        <div className={styles.productSectionContainer}>
          <ScrollableBodyContainer>
            <section className={styles.productWrapper}>
              {
                subcategoryProducts.map((subcategoryProduct, idx) => {

                  return (
                    <div key={idx}>
                      <section id={subcategoryProduct.subcategory} className={styles.categorySectionWrapper} >
                        <div className={styles.categorySectionHeader}>
                          {subcategoryProduct.subcategory.length > 0 ?
                            <div className={styles.categoryTextGroup}>
                              <div className={styles.resultsCountBox}>

                                <span className={styles.catCountNum}>
                                  {subcategoryProduct.products.length}
                                </span>
                                <p className={styles.catCount}>{translatedText.results}</p>
                              </div>
                              <div className={styles.sectionTitleContainer} >
                                <p className={styles.sectionTitle}  >
                                  {subcategoryProduct.subcategory}
                                </p>
                              </div>
                            </div>
                            : null}

                        </div>
                        <div className={styles.allCategoriesProductsContainer}>
                          <ScrollableRow products={subcategoryProduct.products} />
                        </div>
                      </section>
                    </div>
                  )
                })}
            </section>
          </ScrollableBodyContainer>
        </div>
      </div>
      {/* </PageWrapperTemplate> */}



    </>
  );
};

export default CategoryPage;
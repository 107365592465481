import styles from './QRCodeImage.module.css';
import { LanguageContext } from '../../../../../Context/Language.Context';
import { useContext } from 'react';


const QRCodeImage = ({ QRCodeImage }) => {
    const { language } = useContext(LanguageContext);

    const isEnglish = language === "EN";

    // Retrieve product-level QR code based on language
    const productQrCode = isEnglish
        ? QRCodeImage.qrCodeSpecSheetEN

        : QRCodeImage.qrCodeSpecSheetFR;

    // Filter sections to include only those with QR codes based on language
    const sectionQrCodes = QRCodeImage.sections
        .filter(section => isEnglish ? section.resourceQrCodeImageEN : section.resourceQrCodeImageFR)
        .map((section, index) => ({
            title: isEnglish ? section.resourceTitleEN : section.resourceTitleFR,
            imageUrl: `${process.env.REACT_APP_AWS_URL}/${isEnglish ? section.resourceQrCodeImageEN : section.resourceQrCodeImageFR}`,
            key: index
        }));

    // Check if there’s any QR code to display
    // const hasQrCodes = productQrCode || sectionQrCodes.length > 0;

    const nullQrCode = productQrCode === "null";
    const hasQrCodes = !nullQrCode || sectionQrCodes.some(section => section.hasOwnProperty('resourceQrCodeImage'));
    return (
        hasQrCodes ?
            <div className={styles.qrCodeImageContainer}>
                <div className={styles.qrCodeSectionWrapper}>
                    <>
                       
                        {nullQrCode !== false || productQrCode &&
                            <div>
                                <div className={styles.productImageContainer}>
                                    <img src={`${process.env.REACT_APP_AWS_URL}/` + productQrCode} alt={productQrCode} />
                                </div>
                                <p className={styles.title}>{isEnglish ? "Scan QR Code" : "Scanner le code QR"}</p>
                                <p className={styles.itemText}>{isEnglish ? "Specification Sheet" : "Fiche Technique"}</p>
                            </div>
                        }
                        {sectionQrCodes && sectionQrCodes.map(qrCode => (
                            <div key={qrCode.key}>
                                <div className={styles.productImageContainer}>
                                    <img src={qrCode.imageUrl} alt={qrCode.title} />
                                </div>
                                <p className={styles.title}>{isEnglish ? "Scan QR Code" : "Scanner le code QR"}</p>
                                <p className={styles.itemText}>{qrCode.title}</p>
                            </div>
                        ))}

                    </>
                </div>
            </div>
            : null
    );
}

export default QRCodeImage;
import styles from "./MainNavigation.module.css";
import { motion, AnimatePresence } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useContext } from "react";


import { RoutingContext } from "../../Context/Routing.Context";
import { AuthContext } from "../../Context/Auth.Context";
import { LanguageContext } from "../../Context/Language.Context";
import { RetailerContext } from "../../Context/Retailer.Context";

import DropdownWrapper from "../../Shared/Components/UI/DropdownWrapper/DropdownWrapper"
import WelcomeBar from "../../Shared/Components/UI/WelcomeBar/WelcomeBar";
import LanguageSelectionMenu from "../../Shared/Components/UI/LanguageSelectionMenu/LanguageSelectionMenu";
import UserNavigationLinks from "../NavigationLinks/UserNavigationLinks";
import NavListElements from "../../Shared/PurchasePro/Components/NavListElements/NavListElements";

import HomeDepotLogo from "../../Shared/Theme/Branding/HomeDepotLogo"
import LGLogo from "../../Shared/Theme/Branding/LGLogo"

import { usePopUpAnimation } from "../../Hooks/AnimationHook/UseAnimiationHook";

import TheButton from "../../Shared/Components/UI/TheButton/TheButton";
import TheIcon from "../../Shared/Components/UI/TheIcon/TheIcon";
import { ExternalNavigationLinks } from "../../Shared/Components/UI/NavigationElements/ExternalNavigationLinks";
import NavigationLinkBox from "../../Shared/Components/UI/NavigationElements/NavigationLinkBox"
import NavigationLinks from "../../Shared/Components/UI/NavigationElements/NavigationLinks";
import MenuRowToggleBox from "../../Shared/Components/UI/NavigationElements/MenuRowToggleBox";
import ThePageContent from "../../Shared/Components/UI/PageContentContainer/ThePageContent";
import BodyContainer from "../../Shared/Components/UI/BodyContainer/BodyContainer";
import { SearchComponent } from "../../Shared/Components/UI/Search/SearchComponent";

import { translatedText } from "../../Shared/Data/Language/translated-text";

const MainNavigation = () => {
    const animationProps = usePopUpAnimation();
    const { token } = useContext(AuthContext)
    const { translatedText, language } = useContext(LanguageContext)
    const { isHomeDepotApp } = useContext(RetailerContext)
    const { isRoutingState, setIsRoutingState } = useContext(RoutingContext)


    // HUB LINK LOGIC
    const translatedHubLinkLG = language === "EN" ? 'https://lgplus.ca/home-appliance-en/' : 'https://lgplus.ca/fr/electromenager/'
    const translatedHubLinkHD = language === "EN" ? 'https://lgretaillink.com/' : 'https://lgretaillink.com/home-depot-ha-fr/'

    const externalSiteHubs = {
        buttonText: isHomeDepotApp.isHomeDepotActive ? 'LG Retail Link' : 'LG Plus',
        buttonLink: isHomeDepotApp.isHomeDepotActive ? translatedHubLinkHD : translatedHubLinkLG
    }


    const stepUpChartLinkMapLG = [

        // COOKING
        {
            link: language && language === 'EN'
                ? "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Step-Up+Charts/English/LG+Cooking+Step+Up+Chart+En.pdf"
                : "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Step-Up+Charts/French/LG+Cooking+Step+Up+Chart+Fr.pdf"
            ,
            text: `${translatedText.cooking} ${translatedText.stepUpChart}`
        },
        // LAUNDRY
        {
            link: language && language === 'EN'
                ? "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Step-Up+Charts/English/LG+Laundry+Step+Up+Chart.pdf"
                : "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Step-Up+Charts/French/LG+Laundry+Step+Up+Chart+Fr.pdf"
            ,
            text: `${translatedText.laundry} ${translatedText.stepUpChart}`
        },
        // DISHWASHER
        {
            link: language && language === 'EN'
                ? "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Step-Up+Charts/English/LG+Dish+Step+Up+Chart+En.pdf"
                : "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Step-Up+Charts/French/LG+Dish+Step+Up+Chart+Fr.pdf"
            ,
            text: `${translatedText.dishwasher} ${translatedText.stepUpChart}`

        },
        // FRIDGE
        {
            link: language && language === 'EN'
                ? "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Step-Up+Charts/English/LG+Fridge+Step+Up+Chart+En.pdf"
                : "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Step-Up+Charts/French/LG+Fridge+Step+Up+Chart+Fr.pdf"
            ,
            text: `${translatedText.refrigeration} ${translatedText.stepUpChart}`
        }
    ]

    const stepUpChartLinkMapHD = [

        // COOKING
        {
            link: language && language === 'EN'
                ? "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Step+Up+Charts/English/LG+Cooking+Step+Up+Chart+HD+En.pdf"
                : "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Step+Up+Charts/French/LG+Cooking+Step+Up+Chart+HD+Fr.pdf"
            ,
            text: `${translatedText.cooking} ${translatedText.stepUpChart}`
        },
        // LAUNDRY
        {
            link: language && language === 'EN'
                ? "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Step+Up+Charts/English/LG+HD+Laundry+Step+Up+Chart+En.pdf"
                : "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Step+Up+Charts/French/LG+HD+Laundry+Step+Up+Chart+Fr.pdf"
            ,
            text: `${translatedText.laundry} ${translatedText.stepUpChart}`
        },
        // DISHWASHER
        {
            link: language && language === 'EN'
                ? "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Step+Up+Charts/English/LG+HD+Dish+Step+Up+Chart+En.pdf"
                : "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Step+Up+Charts/French/LG+HD+Dish+Step+Up+Chart+Fr.pdf"
            ,
            text: `${translatedText.dishwasher} ${translatedText.stepUpChart}`
        },
        // FRIDGE
        {
            link: language && language === 'EN'
                ? "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Step+Up+Charts/English/LG+HD+Fridge+Step+Up+En.pdf"
                : "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Step+Up+Charts/French/LG+HD+Fridge+Step+Up+Chart+Fr.pdf"
            ,
            text: `${translatedText.refrigeration} ${translatedText.stepUpChart}`
        }
    ]

    const renderRetailerStepUpChartLinks = isHomeDepotApp && isHomeDepotApp.isHomeDepotActive === true
        ? stepUpChartLinkMapHD
        : stepUpChartLinkMapLG;


    const transitionsChartLinkMapLG = [
        {
            link: language && language === 'EN'
                ? "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Model+Transitions/LG+Product+Transitions.pdf"
                : "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Model+Transitions/LG+Product+Transitions+Fr.pdf"
            ,
            text: translatedText.cooking
        },
    ]
    const transitionsChartLinkMapHD = [
        {
            link: language && language === 'EN'
                ? "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Model+Transitions/HD+LG+Product+Transitions.pdf"
                : "https://ha-product-guide.s3.us-east-2.amazonaws.com/Product+Guide+Files/Retail+Link/Model+Transitions/HD+LG+Product+Transitions+Fr.pdf"
            ,
            text: translatedText.cooking
        },
    ]
    const renderRetailerTransitionChartLinks = isHomeDepotApp && isHomeDepotApp.isHomeDepotActive === true
        ? transitionsChartLinkMapHD
        : transitionsChartLinkMapLG;


    //-------------------------------//
    //--MOBILE-HANDLERS 
    //-------------------------------//

    const handleLinkClick = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isMobileNavOpen: false,
            isCategoriesMenuOpen: false,
            isResourcesMenuOpen: false,
            isAccountMenuOpen: false,
            isBuilderDropdownOpen: false,
            isMobileBuilderDropdownOpen: false,
            isNavLinkClicked: true,
        }))
    }

    const toggleMobileNavDropdown = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isMobileNavOpen: !prevState.isMobileNavOpen
        }))
    }

    const toggleAppliances = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isMobileCategoriesMenuOpen: !prevState.isMobileCategoriesMenuOpen
        }))
    }

    const toggleResources = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isMobileResourcesMenuOpen: !prevState.isMobileResourcesMenuOpen
        }))

    }
    const toggleShowAccountMenu = () => {

        setIsRoutingState(prevState => ({
            ...prevState,
            isMobileAccountMenuOpen: !prevState.isMobileAccountMenuOpen
        }))
    }

    //-------------------------------//
    //--DESKTOP-HANDLERS 
    //-------------------------------//

    //---------------APPLIANCES NAV LINK & MENU


    const handleExloreLinkMouseEnter = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isCategoriesMenuOpen: true
        }))
    }

    const handleExloreLinkMouseLeave = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isCategoriesMenuOpen: false
        }))
    }

    const handleExloreDropdownMouseEnter = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isCategoriesMenuOpen: true
        }))
    }

    const handleExloreDropdownMouseLeave = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isCategoriesMenuOpen: false
        }))
    }

    //---------------RESOURCES NAV LINK & MENU ----------------

    const handleResourcesLinkMouseEnter = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isResourcesMenuOpen: true
        }))

    }

    const handleResourcesLinkMouseLeave = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isResourcesMenuOpen: false
        }))
    }

    const handleResourcesDropdownMouseEnter = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isResourcesMenuOpen: true
        }))
    }

    const handleResourcesDropdownMouseLeave = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isResourcesMenuOpen: false
        }))
    }


    //---------------ACCOUNT NAV LINK & MENU ----------------
    const handleAccountLinkMouseEnter = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isAccountMenuOpen: true
        }))
    }
    const handleAccountLinkMouseLeave = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isAccountMenuOpen: false
        }))
    }
    const handleAccountDropdownMouseEnter = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isAccountMenuOpen: true
        }))
    }
    const handleAccountDropdownMouseLeave = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isAccountMenuOpen: false
        }))
    }


    const handleDesktopListIconMouseEnter = () => {
        setIsRoutingState(prevState => ({
            ...prevState,
            isBuilderDropdownOpen: true
        }))
    }



    return (
        <>
            {/* <div className={styles.mainNavGroupContainer}>
                <div className={styles.i1}> */}

            <div className={styles.mainNavGroupContainer}>


                <div className={styles.mainNavContainer}>
                    <WelcomeBar />
                    <BodyContainer>

                        {/* ------------------MOBILE NAV WRAPPER-----------------*/}
                        <div className={styles.mobileNavWrapper}>
                            <section className={styles.mobileNavSection1}>

                                <div className={styles.mobileLogoBox}>
                                    <NavLink
                                        onClick={handleLinkClick}
                                        to={isHomeDepotApp.isHomeDepotActive ? '/home-depot' : '/'}
                                    >
                                        {isHomeDepotApp.isHomeDepotActive ? (<HomeDepotLogo className={styles.logo} />)
                                            : (<LGLogo className={styles.logo} />)}
                                    </NavLink>
                                </div>

                                <section className={styles.mobileNavGeneralBox}>
                                    <SearchComponent type="mobile" />
                                    <div>
                                        <div className={styles.desktopListIconBox}>
                                            <NavListElements />
                                        </div>
                                    </div>

                                    <span onClick={toggleMobileNavDropdown}>
                                        {isRoutingState.isMobileNavOpen ?
                                            <TheIcon name="close" /> :
                                            <TheIcon name="bars" />
                                        }
                                    </span>
                                </section>

                            </section>
                            {/* ----------MOBILE NAVIGATION DROPDOWN---------- */}

                            {isRoutingState.isMobileNavOpen &&
                                <>
                                    <DropdownWrapper>
                                        <motion.div className={styles.mainMobileDropdown}{...animationProps}>
                                            <section className={styles.mobileDropdownSection}>
                                                <MenuRowToggleBox
                                                    onClick={toggleAppliances}
                                                    clickText={translatedText.homeAppliances}
                                                    condition={isRoutingState.isMobileCategoriesMenuOpen}
                                                />
                                                {/* ----------MOBILE NAVIGATION HOME APPLIANCES DROPDOWN---------- */}
                                                <AnimatePresence>
                                                    {
                                                        isRoutingState.isMobileCategoriesMenuOpen &&
                                                        <>
                                                            <motion.div {...animationProps} className={styles.dropdownLinkBoxWrapper}>
                                                                <NavigationLinkBox>
                                                                    <NavigationLinks
                                                                        styleName="menuLinks"
                                                                        className={({ isActive }) => isActive ? styles.active : undefined}
                                                                        linkText={translatedText.laundry}
                                                                        to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/Laundry`}
                                                                        onClick={handleLinkClick}
                                                                    />
                                                                </NavigationLinkBox>
                                                                <NavigationLinkBox>
                                                                    <NavigationLinks
                                                                        styleName="menuLinks"
                                                                        className={({ isActive }) => isActive ? styles.active : undefined}
                                                                        linkText={translatedText.refrigeration}
                                                                        to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/Refrigeration`}
                                                                        onClick={handleLinkClick}
                                                                    />
                                                                </NavigationLinkBox>


                                                                <NavigationLinkBox>
                                                                    <NavigationLinks
                                                                        styleName="menuLinks"
                                                                        className={({ isActive }) => isActive ? styles.active : undefined}
                                                                        linkText={translatedText.cooking}
                                                                        to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/Cooking`}
                                                                        onClick={handleLinkClick}
                                                                    />
                                                                </NavigationLinkBox>


                                                                <NavigationLinkBox>
                                                                    <NavigationLinks
                                                                        styleName="menuLinks"
                                                                        className={({ isActive }) => isActive ? styles.active : undefined}
                                                                        linkText={translatedText.dishwasher}
                                                                        to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/Dishwasher`}
                                                                        onClick={handleLinkClick}
                                                                    />
                                                                </NavigationLinkBox>


                                                                <NavigationLinkBox>
                                                                    <NavigationLinks
                                                                        styleName="menuLinks"
                                                                        className={({ isActive }) => isActive ? styles.active : undefined}
                                                                        linkText="LG Studio"
                                                                        to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/LG Studio`}
                                                                        onClick={handleLinkClick}
                                                                    />
                                                                </NavigationLinkBox>

                                                                <div className={styles.catDropdownGroup}>
                                                                    <NavigationLinkBox>
                                                                        <NavigationLinks
                                                                            styleName="menuLinks"
                                                                            className={({ isActive }) => isActive ? styles.active : undefined}
                                                                            linkText={translatedText.viewAll}
                                                                            to={`${isHomeDepotApp.isHomeDepotPrefix}/categories`}
                                                                            onClick={handleLinkClick}
                                                                        />
                                                                    </NavigationLinkBox>
                                                                </div>
                                                            </motion.div>
                                                        </>
                                                    }
                                                </AnimatePresence>
                                            </section>
                                            {/* ----------MOBILE NAVIGATION RESOURCES DROPDOWN---------- */}
                                            <section className={styles.mobileDropdownSection}>
                                                <MenuRowToggleBox
                                                    onClick={toggleResources}
                                                    clickText={translatedText.resources}
                                                    condition={isRoutingState.isMobileResourcesMenuOpen}
                                                />
                                                <AnimatePresence>
                                                    {
                                                        isRoutingState.isMobileResourcesMenuOpen &&
                                                        <>
                                                            <motion.div {...animationProps}>
                                                                {
                                                                    renderRetailerTransitionChartLinks && renderRetailerTransitionChartLinks.map((e, idx) => (
                                                                        <div className={styles.externalNavLink} key={idx}>
                                                                            <NavigationLinkBox>
                                                                                <ExternalNavigationLinks linkText={translatedText.modelTransitions} to={e.link} />
                                                                            </NavigationLinkBox>
                                                                        </div>
                                                                    ))
                                                                }
                                                                {/* <NavigationLinkBox>
                                                                <NavigationLinks
                                                                    styleName="menuLinks"
                                                                    className={({ isActive }) => isActive ? styles.active : undefined}
                                                                    linkText={translatedText.modelTransitions}
                                                                    to={`${isHomeDepotApp.isHomeDepotPrefix}/resources/transitions`}
                                                                    onClick={handleLinkClick}
                                                                />
                                                            </NavigationLinkBox> */}
                                                                <NavigationLinkBox>
                                                                    <NavigationLinks
                                                                        styleName="menuLinks"
                                                                        className={({ isActive }) => isActive ? styles.active : undefined}
                                                                        linkText={translatedText.featureDefinitions}
                                                                        to={`${isHomeDepotApp.isHomeDepotPrefix}/resources/definitions`}
                                                                        onClick={handleLinkClick}
                                                                    />
                                                                </NavigationLinkBox>
                                                                <NavigationLinkBox>
                                                                    <NavigationLinks
                                                                        styleName="menuLinks"
                                                                        className={({ isActive }) => isActive ? styles.active : undefined}
                                                                        linkText={translatedText.limitedWarranties}
                                                                        to={`${isHomeDepotApp.isHomeDepotPrefix}/resources/warranties`}
                                                                        onClick={handleLinkClick}
                                                                    />
                                                                </NavigationLinkBox>
                                                                {
                                                                    renderRetailerStepUpChartLinks && renderRetailerStepUpChartLinks.map((e, idx) => (
                                                                        <div className={styles.externalNavLink} key={idx}>
                                                                            <NavigationLinkBox>
                                                                                <ExternalNavigationLinks linkText={e.text} to={e.link} />
                                                                            </NavigationLinkBox>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </motion.div>
                                                        </>
                                                    }
                                                </AnimatePresence>
                                            </section>
                                            {/* ADD ACCOUNT MENU HERE */}
                                            {/* ADD ACCOUNT MENU HERE */}
                                            {/* ADD ACCOUNT MENU HERE */}
                                            {/* ----------MOBILE NAVIGATION PURCHASE PRO ---------- */}
                                            <section className={styles.purchaseProSectionContainer}>
                                                <div className={styles.buttonBox}>
                                                    <span onClick={handleLinkClick}> <TheButton
                                                        styleName="red"
                                                        // linkText="Purchase Pro"
                                                        linkText={translatedText.pL}
                                                        link={`${isHomeDepotApp.isHomeDepotPrefix}/product-list`}
                                                    // link={`${isHomeDepotApp.isHomeDepotPrefix}/purchase-pro`}
                                                    />
                                                    </span>
                                                </div>

                                            </section>
                                            <section className={styles.hubSelectionContainer}>
                                                <a
                                                    href={externalSiteHubs.buttonLink}
                                                    target="_blank" rel="noopener noreferrer"
                                                >
                                                    <TheButton>{externalSiteHubs.buttonText}</TheButton>
                                                </a>
                                            </section>
                                            <section className={styles.languageSelectionContainer}>

                                                <LanguageSelectionMenu />
                                            </section>

                                        </motion.div>

                                    </DropdownWrapper>
                                </>
                            }
                        </div>

                        {/* ------------------DESKTOP NAV WRAPPER-----------------*/}
                        <div className={styles.desktopNavWrapper}>

                            <section className={styles.desktopNavSection1}>
                                <div className={styles.desktopLogoBox}>
                                    <NavLink
                                        to={isHomeDepotApp.isHomeDepotActive ? '/home-depot' : '/'}
                                    >
                                        {isHomeDepotApp.isHomeDepotActive ? (<HomeDepotLogo />) : (<LGLogo />)}
                                    </NavLink>
                                </div>

                                {/* ----------DESKTOP NAVIGATION CATEGORIES DROPDOWN---------- */}

                                <section className={styles.desktopCategoryBox}>
                                    <div className={styles.desktopNavCategoryWrapper}>
                                        <p
                                            onMouseEnter={handleExloreLinkMouseEnter}
                                            onMouseLeave={handleExloreLinkMouseLeave}
                                            className={styles.desktopNavHeaderLinkText}
                                        >
                                            {translatedText.explore}
                                        </p>

                                        <AnimatePresence>
                                            {
                                                isRoutingState.isCategoriesMenuOpen &&
                                                <DropdownWrapper>
                                                    <motion.div
                                                        {...animationProps}
                                                        className={styles.desktopCategoryDropdownWrapper}
                                                        onMouseEnter={handleExloreDropdownMouseEnter}
                                                        onMouseLeave={handleExloreDropdownMouseLeave}

                                                    >
                                                        <div className={styles.desktopCategoryDropdown}>
                                                            <div className={styles.desktopDropdownContent}>
                                                                <div className={styles.desktopNavLinkGroup}>
                                                                    <NavigationLinkBox>
                                                                        <NavigationLinks
                                                                            className={({ isActive }) => isActive ? styles.active : undefined}
                                                                            linkText={translatedText.laundry}
                                                                            to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/Laundry`}
                                                                            onClick={handleLinkClick}
                                                                        />
                                                                    </NavigationLinkBox>
                                                                    <NavigationLinkBox>
                                                                        <NavigationLinks
                                                                            className={({ isActive }) => isActive ? styles.active : undefined}
                                                                            linkText={translatedText.refrigeration}
                                                                            to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/Refrigeration`}
                                                                            onClick={handleLinkClick}
                                                                        />
                                                                    </NavigationLinkBox>

                                                                    <NavigationLinkBox>
                                                                        <NavigationLinks
                                                                            className={({ isActive }) => isActive ? styles.active : undefined}
                                                                            linkText={translatedText.cooking}
                                                                            to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/Cooking`}
                                                                            onClick={handleLinkClick}
                                                                        />
                                                                    </NavigationLinkBox>

                                                                    <NavigationLinkBox>
                                                                        <NavigationLinks
                                                                            className={({ isActive }) => isActive ? styles.active : undefined}
                                                                            linkText={translatedText.dishwasher}
                                                                            to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/Dishwasher`}
                                                                            onClick={handleLinkClick}
                                                                        />
                                                                    </NavigationLinkBox>

                                                                    <NavigationLinkBox>
                                                                        <NavigationLinks
                                                                            className={({ isActive }) => isActive ? styles.active : undefined}
                                                                            linkText={translatedText.viewAll}
                                                                            to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/`}
                                                                            onClick={handleLinkClick}
                                                                        />
                                                                    </NavigationLinkBox>
                                                                </div>
                                                            </div>

                                                            <div className={styles.desktopDropdownContent}>
                                                                <div className={styles.desktopNavLinkGroup}>
                                                                    <NavigationLinkBox>
                                                                        <NavigationLinks
                                                                            className={({ isActive }) => isActive ? styles.active : undefined}
                                                                            linkText="LG Studio"
                                                                            to={`${isHomeDepotApp.isHomeDepotPrefix}/categories/LG Studio`}
                                                                            onClick={handleLinkClick}
                                                                        />
                                                                    </NavigationLinkBox>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </motion.div>
                                                </DropdownWrapper>
                                            }
                                        </AnimatePresence>
                                    </div>
                                    {/* ----------DESKTOP NAVIGATION RESOURCES DROPDOWN---------- */}

                                    <div className={styles.desktopResourcesBox}>
                                        <div className={styles.desktopNavResourcesWrapper}>
                                            <p
                                                className={styles.desktopNavHeaderLinkText}
                                                onMouseEnter={handleResourcesLinkMouseEnter}
                                                onMouseLeave={handleResourcesLinkMouseLeave}
                                            >
                                                {translatedText.resources}
                                            </p>
                                            <AnimatePresence>
                                                {
                                                    isRoutingState.isResourcesMenuOpen &&

                                                    <DropdownWrapper>
                                                        <motion.div
                                                            {...animationProps}
                                                            className={styles.desktopCategoryDropdownWrapper}
                                                            onMouseEnter={handleResourcesDropdownMouseEnter}
                                                            onMouseLeave={handleResourcesDropdownMouseLeave}
                                                        >
                                                            <div className={styles.desktopCategoryDropdown}>
                                                                <div className={styles.desktopDropdownContent}>
                                                                    <div className={styles.desktopNavLinkGroup}>
                                                                        {/* <NavigationLinkBox>
                                                                        <NavigationLinks
                                                                            className={({ isActive }) => isActive ? styles.active : undefined}
                                                                            linkText={translatedText.modelTransitions}
                                                                            to={`${isHomeDepotApp.isHomeDepotPrefix}/resources/transitions`}
                                                                            onClick={handleLinkClick}
                                                                        />
                                                                    </NavigationLinkBox> */}
                                                                        {
                                                                            renderRetailerTransitionChartLinks && renderRetailerTransitionChartLinks.map((e, idx) => (
                                                                                <div className={styles.externalNavLink} key={idx}>
                                                                                    <NavigationLinkBox>
                                                                                        <ExternalNavigationLinks linkText={translatedText.modelTransitions} to={e.link} />
                                                                                    </NavigationLinkBox>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                        <NavigationLinkBox>
                                                                            <NavigationLinks
                                                                                className={({ isActive }) => isActive ? styles.active : undefined}
                                                                                linkText={translatedText.featureDefinitions}
                                                                                to={`${isHomeDepotApp.isHomeDepotPrefix}/resources/definitions`}
                                                                                onClick={handleLinkClick}
                                                                            />
                                                                        </NavigationLinkBox>

                                                                        <NavigationLinkBox>
                                                                            <NavigationLinks
                                                                                className={({ isActive }) => isActive ? styles.active : undefined}
                                                                                linkText={translatedText.limitedWarranties}
                                                                                to={`${isHomeDepotApp.isHomeDepotPrefix}/resources/warranties`}
                                                                                onClick={handleLinkClick}
                                                                            />
                                                                        </NavigationLinkBox>
                                                                        {
                                                                            renderRetailerStepUpChartLinks && renderRetailerStepUpChartLinks.map((e, idx) => (
                                                                                <div className={styles.externalNavLink} key={idx}>
                                                                                    <NavigationLinkBox>
                                                                                        <ExternalNavigationLinks linkText={e.text} to={e.link} />
                                                                                    </NavigationLinkBox>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </motion.div>

                                                    </DropdownWrapper>

                                                }
                                            </AnimatePresence>
                                        </div>
                                    </div>
                                    <div className={styles.purchaseProLinkBox}>

                                        <NavigationLinks
                                            styleName="purchaseProHeaderLink"
                                            className={({ isActive }) => isActive ? styles.active : undefined}
                                            // linkText="Purchase Pro"
                                            linkText={translatedText.pL}
                                            to={`${isHomeDepotApp.isHomeDepotPrefix}/product-list`}
                                            // to={`${isHomeDepotApp.isHomeDepotPrefix}/purchase-pro`}
                                            onClick={handleLinkClick}
                                        />

                                    </div>

                                </section>


                                <div className={styles.hubButtonWrapper}>
                                    <a
                                        href={externalSiteHubs.buttonLink}
                                        target="_blank" rel="noopener noreferrer"
                                    >
                                        <TheButton >{externalSiteHubs.buttonText}</TheButton>
                                    </a>
                                </div>


                                <section className={styles.desktopNavGeneralGroupWrapper}>
                                    <div className={styles.desktopAccountBox}
                                        onMouseEnter={handleAccountLinkMouseEnter}
                                        onMouseLeave={handleAccountLinkMouseLeave}
                                    >
                                        {/* ACCOUNT  */}
                                        {/* <div className={styles.desktopNavAccountWrapper}>
                                    <TheIcon name="user" />
                                    <AnimatePresence>
                                        {
                                            isRoutingState.isAccountMenuOpen &&
                                            <DropdownWrapper>
                                                <motion.div
                                                    {...animationProps}
                                                    className={styles.desktopAccountDropdownWrapper}
                                                    onMouseEnter={handleAccountDropdownMouseEnter}
                                                    onMouseLeave={handleAccountDropdownMouseLeave}
                                                >
                                                    {
                                                        token && token ? (
                                                            <div className={styles.desktopDropdownContent}>
                                                                <div className={styles.desktopNavLinkGroup}>
                                                                    <div className={styles.userNavLinksBox}><UserNavigationLinks /></div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className={styles.desktopDropdownContent}>
                                                                <div className={styles.desktopNavLinkGroup}>
                                                                    <NavigationLinkBox>
                                                                        <NavigationLinks
                                                                            className={({ isActive }) => isActive ? styles.active : undefined}
                                                                            linkText={translatedText.signIn}
                                                                            to={`${isHomeDepotApp.isHomeDepotPrefix}/sign-in`}
                                                                            onClick={handleLinkClick}
                                                                        />
                                                                    </NavigationLinkBox>
                                                                    <NavigationLinkBox>
                                                                        <NavigationLinks
                                                                            className={({ isActive }) => isActive ? styles.active : undefined}
                                                                            linkText={translatedText.createAccount}
                                                                            to={`${isHomeDepotApp.isHomeDepotPrefix}/sign-up`}
                                                                            onClick={handleLinkClick}
                                                                        />
                                                                    </NavigationLinkBox>
                                                                </div>
                                                            </div>
                                                        )}
                                                </motion.div>
                                            </DropdownWrapper>
                                        }
                                    </AnimatePresence>
                                </div> */}
                                    </div>
                                    <div on className={styles.desktopListIconBox}
                                        onMouseEnter={handleDesktopListIconMouseEnter}

                                    >
                                        <NavListElements />
                                    </div>
                                    <section className={styles.desktopLanguageSelectionBox}>
                                        <LanguageSelectionMenu />
                                    </section>
                                </section>

                            </section>

                        </div>


                    </BodyContainer>
                    {/* ADD SEARCH HERE */}
                    <div className={styles.navSearchComponentContainer}>
                        <BodyContainer>
                            <div className={styles.navSearchDesktopInput}>
                                <SearchComponent type="desktop" />
                            </div>

                        </BodyContainer>
                    </div>
                </div>

            </div>

            <ThePageContent />
        </>

    )
}

export default MainNavigation;
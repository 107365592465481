import React from 'react';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SFWProductsProvider } from './Context/CategoryContext';
import { LanguageProvider } from './Context/Language.Context';

import { SFWBuilderProvider } from './Context/BuilderContext';

// import { AnalyticsProvider } from './Context/AnalyticsContext';

import { AuthProvider } from './Context/Auth.Context';
import { AdminProvider } from './Context/Admin.Context';
import { HAPGProvider } from './Context/HAPG.Context';
import { RoutingProvider } from './Context/Routing.Context';
import { RetailerProvider } from './Context/Retailer.Context';
import { SearchProvider } from './Context/SearchContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>

  <BrowserRouter>
    <RetailerProvider>
      <RoutingProvider>

        <HAPGProvider>
          <AuthProvider>


            {/* <AnalyticsProvider> */}
            <LanguageProvider>
              <SFWProductsProvider>
                <SFWBuilderProvider>
                  <SearchProvider>
                    <AdminProvider>

                      <App />

                    </AdminProvider>
                  </SearchProvider>
                </SFWBuilderProvider>
              </SFWProductsProvider>
            </LanguageProvider>
            {/* </AnalyticsProvider> */}


          </AuthProvider>
        </HAPGProvider>

      </RoutingProvider>
    </RetailerProvider>
  </BrowserRouter>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

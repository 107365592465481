import { useState, useContext, useEffect } from "react";
import { useHttpClient } from "../../../Hooks/HttpHook/http-hook";
// import ConfirmationModal from "../../../Shared/Components/Modals/ConfirmationModal/ConfirmationModal";
import CustomModal from "../../../Shared/Components/Modals/CustomModal/CustomModal";
import { AdminContext } from "../../../Context/Admin.Context";
import { AuthContext } from "../../../Context/Auth.Context";
import { RoutingContext } from "../../../Context/Routing.Context";
// import Loader from "../../../Shared/Components/Loaders/Loader";
// import Button from "../../../Shared/Components/Buttons/Buttons";
import SelectInput from "../../../Shared/Components/UI/FormElements/Select/Select";
import {jwtDecode} from "jwt-decode";
import PortalForm from "../../PortalComponents/FormElements/PortalForm";
import PortalFormRow from "../../PortalComponents/FormElements/PortalFormRow";
import Modal from "../../../Shared/Components/UI/TheModals/TheModal";
import TheButton from "../../../Shared/Components/UI/TheButton/TheButton";
import { useNavigate } from "react-router-dom";
import styles from "./PortalUserStatusFormPage.module.css"


const PortalUpdateUserStatusFormPage = () => {
    const redirect = useNavigate();
    const { token } = useContext(AuthContext);
    const decodedToken = token ? jwtDecode(token) : null;
    const { sendRequest } = useHttpClient();
    const { setIsRoutingState } = useContext(RoutingContext)
    const { setIsActiveSection, isPortalActionState } = useContext(AdminContext);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showCustomModal, setShowCustomModal] = useState(false);


    const [accountStatus, setAccountStatus] = useState('');
    const [loadedUser, setLoadedUser] = useState(null);

    const [error, setError] = useState();
    // const userId = isEditingUser;
    const userId = isPortalActionState?.editingUser;


    const defaultModal = {
        show: false,
        title: '',
        message: '',
        errorList: [],
        actionText: 'Close',
        wasSuccessful: false,
        onConfirm: null,
        onCancel: null,
        onClose: null,
        cancelText: '',
        confirmText: '',
    }
    const [modalInfo, setModalInfo] = useState(defaultModal);


    const handleAccountStatus = (event) => {
        setAccountStatus(event.target.value);
    };

    // useEffect(() => {
    //     const fetchRequest = async () => {
    //         try {
    //             const data = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/user/${userId}`)
    //             setLoadedUser(data.user)
    //             // console.log(data.user)
    //         } catch (err) { }

    //     }
    //     fetchRequest();

    // }, [sendRequest, userId]);
    useEffect(() => {
        const fetchRequest = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/${userId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
    
                if (!response.ok) {
                    throw new Error('Failed to fetch user data.');
                }
    
                const data = await response.json();
                setLoadedUser(data.user);
                // console.log(data);
            } catch (err) {
                console.error("Error fetching user:", err);
            }
        }
    
        fetchRequest();
    }, [sendRequest, userId]);


    const statusChangeSuccessHanlder = () => {
        setModalInfo({ ...modalInfo, show: false });
        setIsActiveSection(5);
        redirect('/admin')
    }

    const handleUpdateMyUserStatus = (event) => {

        event.preventDefault();
        const hasChanges = (accountStatus !== loadedUser.status);

        if (!hasChanges || accountStatus === '') {
            setModalInfo({
                show: true,
                title: 'Nothing to update',
                message: `No changes were made. Update not required.`,
                errorList: [],
                confirmText: 'Go back',
                onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            })
            // alert('No changes were made.');
            return;
        }
        setModalInfo({
            show: true,
            title: 'Are you sure you want to make changes?',
            message: `You are about to change the user's status. This could affect their ability to use the website. Please confirm.`,
            confirmText: "Confirm",
            onConfirm: userStatusUpdateHandler,
            cancelText: "Cancel",
            onCancel: () => setModalInfo({ ...modalInfo, show: false }),
            onClose: () => setModalInfo({ ...modalInfo, show: false }),
        });
        // setShowConfirmationModal(true);
    }

    const userStatusUpdateHandler = async (event) => {
        event.preventDefault();
        setIsRoutingState(prevState => ({
            ...prevState,
            isLoading: true
        }))

        let errorMessage = [];
        if (!accountStatus) errorMessage.push('Status missing or invalid.');


        if (errorMessage.length > 0) {
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false
            }))
            setModalInfo({
                show: true,
                title: 'Change request failed',
                message: `Please fix the error(s) below`,
                errorList: errorMessage,
                confirmText: 'Try again',
                onCancel: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });
            // setIsLoading(false);
            // setShowCustomModal(true);
            // setError({
            //     title: 'Status not updated',
            //     message: `Please fix the following errors:`,
            //     errorList: errorMessage
            // });
            return;
        }
        try {

            const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/edit-user-status/${userId}`,
                'PATCH',
                JSON.stringify({
                    status: accountStatus,
                    adminId: decodedToken.userId,
                }),
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }

            );

            // setUpdateTheUser(prevState => !prevState);

            if (response.message === 'User status updated.') {
                setIsRoutingState(prevState => ({
                    ...prevState,
                    isLoading: false
                }))
                setModalInfo({
                    show: true,
                    title: 'Status change complete',
                    message: 'The user\'s status has been changed successfully!',
                    errorList: [],
                    confirmText: 'Close',
                    wasSuccessful: true,
                    onConfirm: statusChangeSuccessHanlder,
                    onClose: statusChangeSuccessHanlder
                });

            }
            if (!response.message === 'User status updated.') {

                throw new Error(response.message);
            }

        } catch (error) {
            // console.log('rm',error)
            // setIsLoading(false)
            // setShowCustomModal(true);
            // setError({
            //     title: "Result",
            //     message: error.message,
            //     errorList: null
            // });
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false
            }))
            setModalInfo({
                show: true,
                title: 'Something went wrong',
                message: 'The user\'s status could not be changed. Please contact an administrator for assistance.',
                errorList: [],
                confirmText: 'Try again',
                onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });
            // setIsActiveSection(6)
        } finally {
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false
            }))
            // setIsLoading(false);
        }

    }
    const closeModal = () => {
        setShowCustomModal(false);
    };

    return (
        <>
            <Modal
                icon
                iconName="close"
                styleName="lightIconButton"
                iconOnClick={modalInfo.onClose}
                show={modalInfo.show}
                title={modalInfo.title}
                message={modalInfo.message}
                errorList={modalInfo.errorList}
                closeOnBackdropClick={false}
                actionText={modalInfo.actionText}
                actionBox={
                    <>
                        {modalInfo.onConfirm && (
                            <span onClick={modalInfo.onConfirm}>
                                <TheButton styleName="red" animated="true">
                                    {modalInfo.confirmText}
                                </TheButton>
                            </span>
                        )}
                        {modalInfo.onCancel && (
                            <span onClick={modalInfo.onCancel}>
                                <TheButton styleName="white" animated="true">
                                    {modalInfo.cancelText}
                                </TheButton>
                            </span>
                        )}
                    </>
                }
            />
            <Modal
                show={showConfirmationModal}
                title="Confirmation Required"
                message="This action will change the user's status. Please confirm."
                onConfirm={userStatusUpdateHandler}
                onCancel={() => {
                    setShowConfirmationModal(false);
                }}
            />
            {/* <ConfirmationModal
                show={showConfirmationModal}
                title="Are you sure you want to change the user's status?"
                message="This action will change the user's status. Please confirm."
                confirmText="Change"
                cancelText="Cancel"
                onConfirm={userStatusUpdateHandler}

                onCancel={() => {
                    setShowConfirmationModal(false);
                }}
            /> */}
            {showCustomModal && (
                <CustomModal
                    show={showCustomModal}
                    title={error.title}
                    message={error.message}
                    errorList={error.errorList}
                    closeHandler={closeModal}
                />
            )}
            {/* {
                isLoading || !loadedUser ? */}
                    {/* (<Loader />) : ( */}
                        <PortalForm
                            title="Change Account Status"
                            subtitle="Admin Portal"
                            headline="Select an option below to change the status of a user account."
                            onSubmit={handleUpdateMyUserStatus}

                        >
                            <div className={styles.userInfoWrapper}>
                                <p className={styles.userInfoHeader}>Current Status<span className={styles.userInfoDataText}>{loadedUser?.status}</span></p>
                            </div>
                            <PortalFormRow>

                                <SelectInput
                                    id="status"
                                    name="status"
                                    value={accountStatus}
                                   
                                    onChange={handleAccountStatus}
                                    options={[
                                        { value: "", label: "Select status", disabled: true },
                                        { value: "pending", label: "Pending" },
                                        { value: "approved", label: "Approved" },
                                        { value: "notApproved", label: "Not Approved" },

                                    ]}
                                />
                            </PortalFormRow>
                            {/* <div className={styles.userInfoWrapper}>
                                <p className={styles.userInfoHeader}>Update <span className={styles.userInfoDataText}>{loadedUser.firstName}{loadedUser.lastName}</span></p>
                               
                                <p className={styles.userInfoHeader}>Role: <span className={styles.userInfoDataText}>{loadedUser.role}</span></p>
                                <p className={styles.userInfoHeader}>Email: <span className={styles.userInfoDataText}>{loadedUser.email}</span></p>
                                <p className={styles.userInfoHeader}>Joined:<span className={styles.userInfoDataText}>{loadedUser.joined}</span></p>
                            </div> */}
                            <TheButton
                                styleName="red"
                                animated="true"
                            >
                                Change Status
                            </TheButton>
                            {/* <Button type="submit" styleName="black">Change Status</Button> */}


                        </PortalForm>
                    {/* )
            } */}

        </>
    )

}

export default PortalUpdateUserStatusFormPage;
import { useContext } from "react";
import { RetailerContext } from "../../../../Context/Retailer.Context";
import { LanguageContext } from "../../../../Context/Language.Context";
import BodyContainer from "../../../Components/UI/BodyContainer/BodyContainer";
import { motion } from "framer-motion";
import { useScrollFadeIn } from "../../../../Hooks/AnimationHook/UseAnimiationHook";

import TheButton from "../../../Components/UI/TheButton/TheButton";
import { SFWBuilderContext } from "../../../../Context/BuilderContext";
import { SFWProductsContext } from "../../../../Context/CategoryContext";
import { RoutingContext } from "../../../../Context/Routing.Context";


import SearchResult from "../../Components/SearchResult/SearchResult";

import EmptyList from "../../Components/EmptyList/EmptyList";

import ListUtilitiesBar from "../../Components/ListUtilitiesBar/ListUtilitiesBar";

import PurchaseProPrintScreen from "../../Components/PrintScreen/PurchaseProPrintScreen";
import styles from "./ThePurchaseProPage.module.css"
import PageWrapperTemplate from "../../../Components/UI/Templates/PageWrapperTemplate";

import QRCodeImage from "../../../Components/ProductElements/Details/QRCodeImage/QRCodeImage";

const ThePurchaseProPage = () => {
   
    const { builderListItems } = useContext(SFWBuilderContext);
    const { filteredProducts } = useContext(SFWProductsContext);
    const { setIsRoutingState } = useContext(RoutingContext)
    const { isHomeDepotApp } = useContext(RetailerContext);
    const { translatedText } = useContext(LanguageContext);


 console.log(builderListItems)
    const [cardRef0, cardInView0] = useScrollFadeIn({
        threshold: 0.2,
        triggerOnce: true,
    });
    const [cardRef1, cardInView1] = useScrollFadeIn({
        threshold: 0.2,
        triggerOnce: true,
    });

    const [cardRef2, cardInView2] = useScrollFadeIn({
        threshold: 0.2,
        triggerOnce: true,
    });

    const [cardRef3, cardInView3] = useScrollFadeIn({
        threshold: 0.2,
        triggerOnce: true,
    });

    if (!filteredProducts) {
        setIsRoutingState(prevState => ({ ...prevState, isLoading: true }))
    }

    return (

        <>
            {/* {filteredProducts ? (
                <span className={styles.printBlock}>
                    <SearchHeader />
                </span>
            ) : (
            <Loader />
            )} */}
            {/* <span className={styles.printBlock}>
                <SearchHeader />
            </span> */}
            {builderListItems?.length === 0 ?
                <div className={styles.productListPageContainer}>
                    <PageWrapperTemplate>
                        <BodyContainer>
                            <div className={styles.ppContainer}>
                           
                                <motion.div
                                    ref={cardRef0}
                                    animate={{ opacity: cardInView0 ? 1 : 0, y: cardInView0 ? 0 : 50 }}
                                    transition={{ duration: 0.5 }}
                                    className={styles.ppHeader}>
                                    <div className={styles.ppHeaderHeadline}>
                                        <p className={styles.ppSubtitle} >{translatedText.exclusiveOffers}</p>
                                        <p className={styles.ppTitle} >{translatedText.introPL}</p>
                                        <p className={styles.ppTertiaryTitle} >{translatedText.dySalesTL}</p>
                                    </div>

                                    <div className={styles.ppHeaderDescription}>
                                        <p className={styles.purchaseProBodyText}>{translatedText.descrPL}</p>
                                    </div>
                                    {/* <div div className={styles.buttonBox}>
                                        <TheButton
                                            styleName="red"
                                            linkText={translatedText.tryPLBtn}
                                            link={`${isHomeDepotApp.isHomeDepotPrefix}/product-list`}

                                            animated="true"
                                        />
                                    </div> */}
                                </motion.div>
                            </div>



                        </BodyContainer>
                    </PageWrapperTemplate>


                </div > : null
            }

            {
                builderListItems?.length > 0 ? (
                    <>
                        <div className={styles.productListContent}>
                            <span className={styles.printBlock}><ListUtilitiesBar /></span>
                            {builderListItems.map((item, index) => (
                                <div className={styles.printBlock} key={index}>
                                    {/* <QRCodeImage QRCodeImage={item}/> */}
                                    <SearchResult product={item} />
                                </div>
                            ))}
                        </div>
                    </>
                ) : (<EmptyList />)
            }
            <div className={styles.printScreenContainer}>
                <PurchaseProPrintScreen />
            </div>
        </>

    );
};

export default ThePurchaseProPage;
// import { Button } from "../components/ButtonComponent/Button";
import { LinkComponent } from "../components/Links/LinkComponent";

import { motion } from "framer-motion";
import TheButton from "../Shared/Components/UI/TheButton/TheButton";




// Opacity & Bounce (Spring) Effect
const linkVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",  // Use a spring-like motion
            stiffness: 150,   // How fast the spring will react
            damping: 14,      // How bouncy the motion is
            // duration: 0.5     // Total duration
            // ease: [0.68, -0.55, 0.27, 1.55]  // Bouncy ease for playful motion
        }
    },
    exit: {
        opacity: 0,
        y: -20,
        transition:
        {
            // duration: 0.2, 
            ease: "easeInOut"
        }
    }
};


// ---------------------------------------------------------
// EXTERNAL LINK SUPPORT
// ---------------------------------------------------------
export function ExternalLink({ children, href }) {
    const handleOnClick = (e) => {
        e.preventDefault();
        const width = 600;
        const height = 400;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);

        window.open(href, 'TermsWindow', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`);
    };

    return <a href={href} onClick={handleOnClick}>{children}</a>;
}

export function ExternalLinkButton({ linkText, href }) {
    const handleOnClick = (e) => {
        e.preventDefault();  // Prevents default redirect behavior
        const width = 600;
        const height = 400;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);

        window.open(href, 'TermsWindow', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`);
    };

    return (
        <a href={href} onClick={handleOnClick} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <TheButton
                icon
                iconStyleType='externalLink'
                iconType='externalLink'
                iconPosition="right"
                buttonStyleType="tertiaryGray"
                buttonTextType="action"
                animated={true}
            >
                {linkText}
            </TheButton>
        </a>
    );
}

export function EmailLink({ linkText, href }) {
    return <a href={href}>{linkText}</a>;
}



// ---------------------------------------------------------
// APP-WIDE LINK ROUTING
// ---------------------------------------------------------
export function RouteLinks(links) {

    return links.map((link, index) => (
        <motion.li
            key={index}
            variants={linkVariants}  // Apply the staggered animation variants
            initial="hidden"         // Initial state for each list item
            animate="visible"        // Animate to visible when the dropdown is open
            exit="exit"              // Animate out when the dropdown closes
        // transition={{ delay: index * 0.1 }}  // Add a delay based on the index (staggering effect)
        >
            <LinkComponent
                type='trackedLink'
                href={link.href}
                linkText={link.text}
            />
        </motion.li>
    ));
}



// ---------------------------------------------------------
// URL TRANSFORMATION
// ---------------------------------------------------------


export function NormalizeSlugs(str) {
    return str
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')  // Replace spaces with dashes
        .replace(/[^\w-]+/g, '');  // Remove all non-word characters
}

export const GenerateProductURL = (category, productTitle) => {
    const encodedTitle = encodeURIComponent(productTitle);
    return `/appliances/${category}/${encodedTitle}`;
};


export const homeLink = '/';
export const homeDepotRoot = `/home-depot/`
export const portalLink = `/portal/dashboard`;


import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { SFWProductsContext } from '../../../../Context/CategoryContext';
import FullProductPage from '../../../Components/ProductElements/Containers/FullProductPage/FullProductPage';



const DetailsPage = () => {
  const { productTitle } = useParams();
  const { productsByCategory } = useContext(SFWProductsContext);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Find the product by its name in the productsByCategory object
    for (const category in productsByCategory) {
      const matchedProduct = productsByCategory[category].find(p => p.title === productTitle);
      if (matchedProduct) {
        setProduct(matchedProduct);
        break;
      }
    }
  }, [productTitle, productsByCategory]);


  return (
    <>
      {product &&<FullProductPage product={product} />}

    </>
  );
};

export default DetailsPage;
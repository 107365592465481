// DONE
import { useContext } from 'react';
import { LanguageContext } from '../../../../../Context/Language.Context';

import styles from './Specifications.module.css';

const SpecsContainer = ({ product }) => {
  // console.log('spec container', product)
  const { language } = useContext(LanguageContext);

  const specListGroup1 = language === "EN" ? product.specList1EN : product.specList1FR;
  const specTitle1 = language === "EN" ? product.specTitle1EN : product.specTitle1FR;

  const specListGroup2 = language === "EN" ? product.specList2EN : product.specList2FR;
  const specTitle2 = language === "EN" ? product.specTitle2EN : product.specTitle2FR;

  const specListGroup3 = language === "EN" ? product.specList3EN : product.specList3FR;
  const specTitle3 = language === "EN" ? product.specTitle3EN : product.specTitle3FR;

  const specListGroup4 = language === "EN" ? product.specList4EN : product.specList4FR;
  const specTitle4 = language === "EN" ? product.specTitle4EN : product.specTitle4FR;
  // console.log({
  //   'specTitle2': specTitle2,
  //   'specList2': specListGroup2
  // })
  // console.log(specListGroup4.length)
  return (
    <div className={styles.specsSectionWrapper}>
      <section className={styles.specsContainer}>
        {/* <div className={styles.titleBox}>
        <p className={styles.titleText}>What's Included</p>
      </div> */}
        <div className={styles.specsWrapper}>
          {specTitle1 && specListGroup1 && specListGroup1.length > 0 &&
            <section className={styles.specificationsColoumn}>
              <div className={styles.specListTitleBox}>
                <p className={styles.specListTitleText}>
                  {specTitle1}
                </p>
              </div>
              <ul className={styles.specificationsList}>
                {specListGroup1.map((item, index) => (
                  <li className={styles.specListItemText} key={index}>{item}</li>
                ))}
              </ul>
            </section>
          }
          {/* {specTitle2 && specListGroup2 && specListGroup2.length > 0 && */}
          {specTitle2 && specListGroup2 && specListGroup2.length > 0 &&
            <section className={styles.specificationsColoumn}>
              <div className={styles.specListTitleBox}>
              <p className={styles.specListTitleText}>{specTitle2}</p>
              </div>
              <ul className={styles.specificationsList}>
                {specListGroup2.map((item, index) => (
                  <li className={styles.specListItemText} key={index}>{item}</li>
                ))}
              </ul>
            </section>
          }
          {specTitle3 && specListGroup3 && specListGroup3.length > 0 &&
            <section className={styles.specificationsColoumn}>
              <div className={styles.specListTitleBox}>
                <p className={styles.specListTitleText}>{specTitle3}</p>
              </div>
              <ul className={styles.specificationsList}>
                {specListGroup3.map((item, index) => (
                  <li className={styles.specListItemText} key={index}>{item}</li>
                ))}
              </ul>
            </section>
          }
          {
            specTitle4 && specListGroup4 && !(specListGroup4.length === 1 && !specListGroup4[0]) &&
          // specTitle4 && specListGroup4 && specListGroup4.length > 0 &&
            <section className={styles.specificationsColoumn}>
              <div className={styles.specListTitleBox}>
                <p className={styles.specListTitleText}>{specTitle4}</p>
              </div>
              <ul className={styles.specificationsList}>
                {specListGroup4.map((item, index) => (
                  <li className={styles.specListItemText} key={index}>{item}</li>
                ))}
              </ul>
            </section>
          }
        </div>
        
      </section>
    </div>
  );
};

export default SpecsContainer;
import { useContext } from "react";

import { LanguageContext } from "../../../../../Context/Language.Context";
import { Link } from "react-router-dom";
// import Icon from "../../../IconElements/Icon";
// import Button from "../../../Buttons/Buttons";
import TheButton from "../../../UI/TheButton/TheButton";

// import SpecSheetLink from '../../../../Themes/Icons/clipboard-text.svg';
// import ManualLink from '../../../../Themes/Icons/book-open.svg';
// import InstallLink from '../../../../Themes/Icons/tool.svg';
import styles from './FeatureResources.module.css'
import TheIcon from "../../../UI/TheIcon/TheIcon";
import BodyContainer from "../../../UI/BodyContainer/BodyContainer";

const FeatureResources = ({ product }) => {

    const { language } = useContext(LanguageContext);
    const isEnglish = language === "EN";

    const links = product.specSheetLinkEN || product.specSheetLinkFR;

    const validateSpecSheetLink = (specLink) => {
        if (specLink === '' || specLink === null) {
            return null
        } else {
            return specLink;
        }
    }

    const testSpecLinks = validateSpecSheetLink(links);
    console.log(testSpecLinks)
    const resources = [
        {
            resourceTitle: isEnglish ? "Specification Sheet" : "Fiche Technique",
            resourceUrl: isEnglish ? product.specSheetLinkEN : product.specSheetLinkFR,
        },
        {
            resourceTitle: isEnglish ? "Owner's Manual" : "Le manuel du propriétaire",
            resourceUrl: isEnglish ? product.ownerManualLinkEN : product.ownerManualLinkFR,
        },
        {
            resourceTitle: isEnglish ? "Installation Guide" : "Guide d'installation",
            resourceUrl: isEnglish ? product.installGuideLinkEN : product.installGuideLinkFR,
        },
        ...(product.sections || []).map(section => ({
            resourceTitle: isEnglish ? section.resourceTitleEN : section.resourceTitleFR,
            resourceUrl: isEnglish ? section.resourceUrlEN : section.resourceUrlFR,
        }))
    ].filter(item => item.resourceUrl && item.resourceTitle);
    return (
        (testSpecLinks !== null || product.sections.length !== 0) && (
            <div className={styles.resourcesSectionWrapper}>
                <BodyContainer>
                    <div className={styles.resourcesContainer}>
                        <div className={styles.titleBox}>
                            <p className={styles.titleText}>Resources</p>
                        </div>
                        <div className={styles.resourceLinksWrapper}>
                            {resources.map((resource, idx) => (
                                <a key={idx} href={resource.resourceUrl} className={styles.resourceLink} target="_blank" rel="noopener noreferrer">
                                    <TheButton
                                        animated="true"
                                        icon
                                        buttonIcon
                                        iconName="externalLink"
                                        iconText={resource.resourceTitle}
                                        styleName="lightButtonIcon"
                                    />
                                </a>
                            ))}
                        </div>
                    </div>
                </BodyContainer>
            </div>
        )
    );
};

export default FeatureResources;
import { useContext, useState, useMemo, useEffect } from "react";
// import styles from "../Backend/AdminPortal/Components/DataVisualizationElements/DataVisualizationElements.module.css"
// import styles from '../../../Backend/AdminPortal/Components/DataVisualizationElements/DataVisualizationElements.module.css'
import { format } from 'date-fns';
import Icon from "../../../Shared/Components/IconElements/Icon";
import TheTable from "../../PortalComponents/TableElements/TheTable"
import TheTableHeader from "../../PortalComponents/TableElements/TheTableHeader"
import TheTablePagination from "../../PortalComponents/TableElements/TheTablePagination"
import { useNavigate } from 'react-router-dom';
import { SFWProductsContext } from "../../../Context/CategoryContext";
import { AdminContext } from "../../../Context/Admin.Context";
import { AuthContext } from "../../../Context/Auth.Context";
import { RoutingContext } from "../../../Context/Routing.Context";
// import ConfirmationModal from "../../../Shared/Components/Modals/ConfirmationModal/ConfirmationModal";
import {jwtDecode} from "jwt-decode";
import TheTableActionBox from "../../PortalComponents/TableElements/TheTableActionBox";
import TheTableSortingHeader from "../../PortalComponents/TableElements/TheTableSortingHeader";
import Modal from "../../../Shared/Components/UI/TheModals/TheModal";
import SearchInput from "../../../Shared/PurchasePro/Components/SearchInput/SearchInput"
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import TheButton from "../../../Shared/Components/UI/TheButton/TheButton";
import TheTableWrapper from "../../PortalComponents/TableElements/TheTableWrapper";
const AllProductsTablePage = () => {

    const redirect = useNavigate()
    const { setIsRoutingState } = useContext(RoutingContext)
    const { token } = useContext(AuthContext);
    const decodedToken = token ? jwtDecode(token) : null;
    const { products, setProducts } = useContext(SFWProductsContext);
    const [searchField, setSearchField] = useState('');
    const [filteredProducts, setFilteredProducts] = useState(products);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [sortDescending, setSortDescending] = useState(true);  // NEW
    const { setIsPortalActionState, setIsActiveSection, } = useContext(AdminContext);

    const [selectedProductForDeletion, setSelectedProductForDeletion] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const defaultModal = {
        show: false,
        title: '',
        message: '',
        errorList: [],
        actionText: 'Close',
        wasSuccessful: false,
        onConfirm: null,
        onCancel: null,
        onClose: null,
        cancelText: '',
        confirmText: '',
    }
    const [modalInfo, setModalInfo] = useState(defaultModal);


    useEffect(() => {
        if (searchField.length >= 2) {
            const newFilteredProducts = products.filter((product) => {
                return product.title && product.title.toLocaleLowerCase().includes(searchField);
            });
            setFilteredProducts(newFilteredProducts);
        } else {
            setFilteredProducts(products);
        }
    }, [products, searchField]);

    const onSearchChange = (event) => {
        const searchFieldString = event.target.value.toLocaleLowerCase();
        setSearchField(searchFieldString);
        setIsSearchActive(searchFieldString.length > 0);
    };

    // Function to handle sort change
    const handleSortChange = () => {  // NEW
        setSortDescending(prevState => !prevState);
    }

    const productDeleteSuccessHanlder = () => {
        setModalInfo({ ...modalInfo, show: false });
        setIsActiveSection(2);
        redirect('/admin')
    }


    //TABLE DATA
    const columns = [
        { key: 'availability', title: 'Availability' },
        { key: 'store', title: 'Retailer' },
        { key: 'title', title: 'Model Name' },
        { key: 'categoryEN', title: 'Category' },
        { key: 'msrp', title: 'MSRP' },
        // { key: 'creator', title: 'Creator' },
        {
            key: 'createdAt',
            title: (
                <TheTableSortingHeader>
                    <p>Created</p>
                    {sortDescending ?
                        (
                            <div onClick={handleSortChange}>
                                <Icon name="caret-down" />
                            </div>
                        ) :
                        (
                            <div onClick={handleSortChange}>
                                <Icon name="caret-up" />
                            </div>
                        )
                    }
                </TheTableSortingHeader>
            ),
            render: row => format(new Date(row.createdAt), 'dd/MM/yyyy')
        },
        {
            key: 'updatedAt',
            title: 'Last Updated',
            render: row => format(new Date(row.updatedAt), 'dd/MM/yyyy')
        },
        {
            key: 'actions',
            title: 'Actions',
            render: row => (
                <TheTableActionBox>
                    <span onClick={() => { redirect(`/product-details/${row.title}`) }}>
                        <Icon name="view" />
                    </span>
                    <span
                        onClick={
                            () => {
                                setIsPortalActionState(prevState => ({
                                    ...prevState, editingProduct: row._id,
                                }))
                                setIsActiveSection(4)
                            }
                        }>
                        <Icon name="edit" />
                    </span>
                    <span
                        onClick={
                            () => {
                                setIsPortalActionState(prevState => ({
                                    ...prevState, copyProduct: row._id,
                                }))
                                setIsActiveSection(11)
                            }}>
                        <Icon name="duplicate" />
                    </span>
                    <span
                        onClick={() => {
                            setSelectedProductForDeletion(row?._id);
                            setShowConfirmationModal(true);
                        }}>
                        <Icon name="delete" />
                    </span>

                </TheTableActionBox>
            )
        }
    ];

    // Delete function
    const handleDeleteClick = async (productId) => {

        setIsRoutingState(prevState => ({ ...prevState, isLoading: true }))
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/delete-product/${productId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    adminId: decodedToken.userId,
                })
            });
            if (response.status === 200) {
                setProducts(prevProducts => prevProducts.filter(product => product._id !== productId));

                // setIsRoutingState(prevState => ({ ...prevState, isLoading: false }))
                setModalInfo({
                    show: true,
                    title: 'Product deleted',
                    message: 'The product was deleted successfully!',
                    errorList: [],
                    confirmText: 'Close',
                    wasSuccessful: true,
                    onConfirm: productDeleteSuccessHanlder,
                    onClose: productDeleteSuccessHanlder
                });


            } else {
                setModalInfo({
                    show: true,
                    title: 'Something went wrong',
                    message: 'The product could not be deleted. Please contact an administrator for assistance.',
                    errorList: [],
                    confirmText: 'Try again',
                    onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                    onClose: () => setModalInfo({ ...modalInfo, show: false }),
                });
            }

        } catch (error) {

            setModalInfo({
                show: true,
                title: 'Something went wrong',
                message: 'The product could not be deleted. Please contact an administrator for assistance.',
                errorList: error.message, //TEMP - should be []
                confirmText: 'Try again',
                onConfirm: () => setModalInfo({ ...modalInfo, show: false }),
                onClose: () => setModalInfo({ ...modalInfo, show: false }),
            });

        } finally {
            setIsRoutingState(prevState => ({
                ...prevState,
                isLoading: false
            }))
        }

    };



    return (
        <>
            <TheTableWrapper>
                <TheTableHeader
                    title="Products"
                    subtitle="Admin Portal"
                    headline="Select a product below or search by model name."
                />
                {/* <TheTable columns={columns} data={products.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)} /> */}
                <SearchInput
                    id="search"
                    icon={isSearchActive ? undefined : faSearch}
                    onChange={onSearchChange}
                />
                <TheTable columns={columns} data={filteredProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)} />
                <TheTablePagination
                    itemsPerPage={itemsPerPage}
                    // tableData={products}
                    tableData={filteredProducts}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
            </TheTableWrapper>

            <Modal
                icon
                iconName="close"
                styleName="baseIconButton"
                iconOnClick={modalInfo.onClose}
                show={modalInfo.show}
                title={modalInfo.title}
                message={modalInfo.message}
                errorList={modalInfo.errorList}
                closeOnBackdropClick={false}
                actionText={modalInfo.actionText}
                actionBox={
                    <>
                        {modalInfo.onConfirm && (
                            <span onClick={modalInfo.onConfirm}>
                                <TheButton styleName="red" animated='true'>
                                    {modalInfo.confirmText}
                                </TheButton>
                            </span>
                        )}
                        {modalInfo.onCancel && (
                            <span onClick={modalInfo.onCancel}>
                                <TheButton styleName="black" animated='true'>
                                    {modalInfo.cancelText}
                                </TheButton>
                            </span>
                        )}
                    </>
                }
            />
            <Modal
                show={showConfirmationModal}
                title="You sure about this?"
                message="This action will delete this product. Are you sure you want to proceed?"
                onConfirm={() => {
                    handleDeleteClick(selectedProductForDeletion);
                    setShowConfirmationModal(false);
                    setSelectedProductForDeletion(null); // reset the selected product
                }}
                onCancel={() => {
                    setShowConfirmationModal(false);
                    setSelectedProductForDeletion(null); // reset the selected product
                }}

            />
        </>
    )
}

export default AllProductsTablePage
import styles from "./HeroContainer.module.css"

import { motion } from "framer-motion";
import { NavLink, useLocation } from "react-router-dom";
// import Button from "../Buttons/Buttons";
// import Icon from "../IconElements/Icon";
// import { AnalyticsContext } from "../../../../Context/AnalyticsContext";
import TheButton from "../TheButton/TheButton";
import TheIcon from "../TheIcon/TheIcon";
import BodyContainer from "../BodyContainer/BodyContainer";

const HeroContainer = ({
    backgroundImage,
    backgroundPosition,
    title,
    subtitle,
    linkText,
    linkedSubtitle = false,
    linkUrl,

    buttonElement = false,
    buttonBox = false,
    buttonText,

    buttonIcon,
    linkIcon = false,
    iconName,
    studioExclusion = '',
    topTitle,
    marginTop,

}) => {

    // const { storeButtonInteraction } = useContext(AnalyticsContext)
    // const location = useLocation();
    // const currentPage = location.pathname;


    return (
        // <BodyContainer>
        <div className={styles.heroMarginWrapper}>
                <div
                    className={styles.heroContainer}

                    style={{
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.314)),
                    linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.166)), 
               url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: backgroundPosition ? backgroundPosition : null,
                        marginTop: marginTop ? marginTop: undefined
                    }}
                >
                    <div className={styles.textBox}>

                        {topTitle ?

                            (
                                <motion.p
                                    className={styles.topTitleText}
                                    initial={{ opacity: 0, x: 20 }}
                                    animate={{
                                        opacity: 1,
                                        x: 0,
                                        transition: {
                                            duration: .3,
                                            ease: [0.75, 0, 0, 1],
                                        },
                                    }}
                                    exit={{
                                        opacity: 0,
                                        x: 50,
                                        transition: {
                                            duration: .3,
                                            ease: [0.75, 0, 0, 1],
                                        },
                                    }}
                                >
                                    {topTitle}
                                </motion.p>

                            ) : null}

                        <motion.p
                            className={styles.titleText}
                            initial={{ opacity: 0, x: 20 }}
                            animate={{
                                opacity: 1,
                                x: 0,
                                transition: {
                                    duration: .3,
                                    ease: [0.75, 0, 0, 1],
                                },
                            }}
                            exit={{
                                opacity: 0,
                                x: 50,
                                transition: {
                                    duration: .3,
                                    ease: [0.75, 0, 0, 1],
                                },
                            }}
                        >
                            {title}
                        </motion.p>

                        <motion.p
                            className={styles.subtitleText}
                            initial={{ opacity: 0, x: 20 }}
                            animate={{
                                opacity: 1,
                                x: 0,
                                transition: {
                                    duration: .3,
                                    ease: [0.75, 0, 0, 1],
                                },
                            }}
                            exit={{
                                opacity: 0,
                                x: 50,
                                transition: {
                                    duration: .3,
                                    ease: [0.75, 0, 0, 1],
                                },
                            }}
                        >
                            {linkedSubtitle && studioExclusion !== 'LG Studio' ?
                                (
                                    <span
                                    // onClick={() => storeButtonInteraction(`click_from_${currentPage}_to_${linkUrl}`)} className={styles.navLinkTextWrapper}
                                    >
                                        <NavLink to={linkUrl}>
                                            <motion.p
                                                className={styles.linkedSubtitleText}
                                                initial={{ opacity: 0, x: 20 }}
                                                animate={{
                                                    opacity: 1,
                                                    x: 0,
                                                    transition: {
                                                        duration: .3,
                                                        ease: [0.75, 0, 0, 1],
                                                    },
                                                }}
                                                exit={{
                                                    opacity: 0,
                                                    x: 50,
                                                    transition: {
                                                        duration: .3,
                                                        ease: [0.75, 0, 0, 1],
                                                    },
                                                }}
                                            >
                                                {linkText}
                                            </motion.p>



                                        </NavLink>
                                        {
                                            linkIcon ?
                                                (<TheIcon name={iconName} />)
                                                : null
                                        }
                                    </span>
                                ) :
                                null
                            }
                            {subtitle ?

                                (
                                    <motion.p

                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{
                                            opacity: 1,
                                            x: 0,
                                            transition: {
                                                duration: .3,
                                                ease: [0.75, 0, 0, 1],
                                            },
                                        }}
                                        exit={{
                                            opacity: 0,
                                            x: 50,
                                            transition: {
                                                duration: .3,
                                                ease: [0.75, 0, 0, 1],
                                            },
                                        }}
                                    >
                                        {subtitle}
                                    </motion.p>

                                ) : null}


                        </motion.p>


                        {buttonElement ?
                            <TheButton
                                buttonIcon={buttonIcon ? buttonIcon === true : null}>
                                {buttonText}
                            </TheButton>
                            : null
                        }

                        {buttonBox ?
                            <div className={styles.buttonBoxWrapper}>
                                {buttonBox}
                            </div>
                            : null
                        }


                    </div>


                </div>
            </div>
        // </BodyContainer>
    )
}

export default HeroContainer;
import ProductCard from '../../Cards/ProductCard'
import { motion } from 'framer-motion'
import { useRef, useEffect, useState } from 'react'

import styles from './ScrollableRow.module.css'
import TheButton from '../../../UI/TheButton/TheButton'

const ScrollableRow = ({ products, categoryName, productCount }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const categoryDisplayRef = useRef();
    const scrollLeft = () => {
        categoryDisplayRef.current.scrollLeft += -1000;
    }
    const scrollRight = () => {
        categoryDisplayRef.current.scrollLeft += 1000;
    }

    return (
        <>
            <div className={styles.container} id={products}>
                <div className={styles.arrowBox}>
                    <div onClick={scrollLeft}>

                        <TheButton
                            icon
                            buttonIcon
                            iconName="scrollLeft"
                            styleName="lightIconButton"
                            animated
                        />
                    </div>
                    <div onClick={scrollRight}>
                        <TheButton
                            icon
                            buttonIcon
                            iconName="scrollRight"
                            styleName="lightIconButton"
                            animated
                        />
                    </div>
                </div>

                <section className={styles.wrapperScroll} ref={categoryDisplayRef}>
                    {
                        products.sort((a, b) => (b.msrp || 0) - (a.msrp || 0))
                            .map((product, idx) => {
                                return (
                                    <motion.div
                                        key={idx}
                                        transition={{ duration: 0.7 }}
                                        whileHover={
                                            isMobile
                                                ? {}
                                                : { scale: 1.02, transition: { duration: .2, } }
                                        }
                                        className={styles.card}
                                    >
                                        <ProductCard product={product} />
                                    </motion.div>
                                )
                            })
                    }
                </section>
            </div>
            {categoryName &&
                // <h1>{categoryName} ({productCount})</h1>
                <div className={styles.previewFooter}>
                    <h1>Browse All {categoryName} Products </h1>
                </div>
            }
        </>
    )
}

export default ScrollableRow;